import React from 'react';
import thum from "./../../../img/sherut-leumi/svg/sherutPlaces/card/thum.svg";
import garin from "./../../../img/sherut-leumi/svg/sherutPlaces/card/garin.svg";
import tekenB from "./../../../img/sherut-leumi/svg/sherutPlaces/card/teken.svg";
import tekenH from "./../../../img/sherut-leumi/svg/sherutPlaces/card/tekenH.svg"; 
import tekenP from "./../../../img/sherut-leumi/svg/sherutPlaces/card/tekenP.svg";

const ShowDataInRow = ({ item }) => {
  return (
    <div className="ShowDataInRow">
      <header>
        <h2>{item.MOSADNA || 'שם מקום - ' + item.MOSADNA}</h2>
        <p>{item?.City_Value}</p>
      </header>
      
      <ul className="iconsLine">
        <li>
          <img src={thum} alt="" />
          <span>תחום: </span>
          <span>
           
            {item?.Thum?.value2 === "0" || !item?.Thum?.value2 ? "" : ` ${item?.Thum?.value2}`}
          </span>
        </li>

        <li>
          <span>שנה: </span>
          <span>{item.YEAR}</span>
        </li>

        {parseInt(item.TEKENB) > 0 && (
          <li>
            <img src={tekenB} alt="" />
            <span>תקן: בית - </span>
            <span>{item.TEKENB}</span>
            {" "} <span>פנויים</span>{" "}
          </li>
        )}

        {parseInt(item.TEKENP) > 0 && (
          <li>
            <img src={tekenP} alt="" />
            <span>תקן: פנימיה - </span>
            <span>{item.TEKENP}</span>
            {" "}  <span>פנויים</span>{" "}
          </li>
        )}

        {parseInt(item.TEKENH) > 0 && (
          <li>
            <img src={tekenH} alt="" />
            <span>תקן: חוץ - </span>
            <span>{item.TEKENH}</span>
            {" "} <span>פנויים</span>{" "}
          </li>
        )}

        {item.grain === "1" && (
          <li>
            <img src={garin} alt="" />
            <span>גרעין</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ShowDataInRow;
