import React, { useState, useEffect } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import getDataFromApi from '../../../Components/-Helpers-/api/getDataFromApi';
import MultiSelectReact from './MultiSelectReact';
import { getAllUrlParams } from '../../../Components/-Helpers-/UrlParameters';

const SearchUpPlaces = ({ currentState, updateSearch, mobile, closeSearch }) => {
  const history = useHistory();
  const urlParams = getAllUrlParams(window.location.search);

  const [state, setState] = useState({
    searchDataResp: false,
    loading: false,
    yearChanged: false,
    freeSearch: currentState?.freeSearch || (urlParams?.freesearch ? decodeURIComponent(urlParams.freesearch) : ''),
    Maslol: currentState?.Maslol || (urlParams?.maslol ? decodeURIComponent(urlParams.maslol) : ''),
    Thum: currentState?.Thum || (urlParams?.thum ? decodeURIComponent(urlParams.thum).split(',') : []),
    YEAR: currentState?.YEAR || (urlParams?.year ? urlParams.year : ''),
    ZoneName: currentState?.ZoneName || (urlParams?.zonename ? decodeURIComponent(urlParams.zonename) : ''),
    City: currentState?.City || (urlParams?.city ? decodeURIComponent(urlParams.city) : ''),
    clearMulti: false,
    updateFilters: false
  });

  useEffect(() => {
    if (!state.searchDataResp) {
      sendToAdmin('getMekSherutSearchParams', 'mekSherut', {}, 'searchDataResp', 'mekSherut');
    }
  }, [state.searchDataResp]);

  const sendToAdmin = async (url, controller, objectToSend, setStateName = 'data', auth = 'all') => {
    setState(prev => ({ ...prev, loading: true }));

    try {
      const data = await getDataFromApi(url, objectToSend, controller, auth);
      setState(prev => ({
        ...prev,
        [setStateName]: data,
        loading: false,
        YEAR: data?.currentYear?.substring(1)
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(`[DEBUG] Field Changed: ${name}, New Value: ${value}`);
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeThum = (params) => {
    console.log(`[DEBUG] Thum Changed, New Value:`, params);
    setState(prev => ({ ...prev, Thum: params }));
  };

  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    console.log(`[DEBUG] Select Changed: ${name}, New Value: ${value}`);
    setState(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'ZoneName' && value === '' ? { City: '' } : {}),
      ...(name === 'YEAR' ? { yearChanged: true } : {})
    }));
  };

  const sendForm = () => {
    setState(prev => ({ ...prev, updateFilters: Date.now() }));
    updateSearch(state);
    
    // Create URL parameters
    const params = new URLSearchParams();
    if (state.freeSearch) params.append('freesearch', encodeURIComponent(state.freeSearch));
    if (state.Maslol) params.append('maslol', encodeURIComponent(state.Maslol));
    if (state.Thum && state.Thum.length > 0) params.append('thum', encodeURIComponent(state.Thum.join(',')));
    if (state.YEAR) params.append('year', state.YEAR);
    if (state.ZoneName) params.append('zonename', encodeURIComponent(state.ZoneName));
    if (state.City) params.append('city', encodeURIComponent(state.City));

    // Update URL with new parameters using history.push
    history.push(`?${params.toString()}`);

    if (mobile) {
      closeSearch();
    }
  };

  const clearFields = () => {
    setState(prev => ({
      ...prev,
      freeSearch: '',
      Maslol: '',
      Thum: [],
      YEAR: 'תשפ"הדשצק',
      ZoneName: '',
      City: '',
      clearMulti: Date.now()
    }));
    updateSearch(state);
  };

  const searchFilters = (param, isMalol) => {
    if (isMalol) {
      setState(prev => ({
        ...prev,
        freeSearch: '',
        Maslol: '',
        Thum: '',
        YEAR: 'תשפ"ג',
        ZoneName: '',
        City: ''
      }));
    } else {
      setState(prev => ({ ...prev, [param]: '' }));
      sendForm();
    }
  };

  const renderDesktopForm = () => (
    <div>
      <div className="line inputs3">
        <Form.Group className="inputsWithLabel selectIco maslulIco iconAfter">
          <Form.Label style={{ color: "white" }}>מסלול</Form.Label>
          <FormControl
            as="select"
            className="inputsWithLabel selectIcoBG"
            onChange={handleChangeSelect}
            name="Maslol"
            value={state.Maslol}
          >
            <option value="">בחרו מסלול</option>
            <option disabled>----</option>
            {state.searchDataResp?.tracks?.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </FormControl>
        </Form.Group>

        <Form.Group className="inputsWithLabel shanaIco iconAfter">
          <Form.Label style={{ color: "white" }}>שנה</Form.Label>
          <FormControl
            as="select"
            className="inputsWithLabel selectIcoBG"
            onChange={handleChangeSelect}
            name="YEAR"
            disabled={!state.YEAR}
            value={!state.yearChanged ? decodeURI(urlParams.year) : state.YEAR}
          >
            <option value="">בחרו שנה</option>
            <option disabled>----</option>
            {state.searchDataResp?.years?.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </FormControl>
        </Form.Group>

        <Form.Group className="inputsWithLabel">
          <Form.Label style={{ color: "white" }}>תחום</Form.Label>
          <MultiSelectReact
            currentState={state}
            disabled={!state.Maslol}
            clearMulti={state.clearMulti}
            options={state.searchDataResp?.categories || []}
            handleChangeThum={handleChangeThum}
          />
        </Form.Group>
      </div>

      <div className="line inputs4">
        <div className="input3Cont inputs3">
          <Form.Group className="inputsWithLabel selectIco ezorIco iconAfter">
            <Form.Label style={{ color: "white" }}>איזור</Form.Label>
            <FormControl
              as="select"
              className="inputsWithLabel selectIcoBG"
              onChange={handleChangeSelect}
              name="ZoneName"
              value={state.ZoneName}
              disabled={!state.Maslol}
            >
              <option value="">בחרו איזור</option>
              <option disabled>----</option>
              {state.searchDataResp?.zones?.map(item => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </FormControl>
          </Form.Group>

          <Form.Group className="inputsWithLabel placeIco iconAfter">
            <Form.Label style={{ color: "white" }}>ישוב</Form.Label>
            <FormControl
              as="select"
              className="inputsWithLabel selectIcoBG"
              onChange={handleChangeSelect}
              name="City"
              value={state.City}
              disabled={!(state.Maslol && state.ZoneName)}
            >
              <option value="">בחרו ישוב</option>
              <option disabled>----</option>
              {state.searchDataResp?.cities?.map(item => {
                if (state.ZoneName && item.zoneCode === state.ZoneName) {
                  return (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  );
                }
                return null;
              })}
            </FormControl>
          </Form.Group>

          <Form.Group className="inputsWithLabel searchIco">
            <Form.Label style={{ color: "white" }}>חיפוש חופשי</Form.Label>
            <FormControl
              disabled={!state.Maslol}
              placeholder="לדוגמא: חינוך מיוחד"
              className="inputsWithLabel"
              onChange={handleChange}
              name="freeSearch"
              value={state.freeSearch}
              type="text"
            />
          </Form.Group>
        </div>

        <Button
          disabled={!state.Maslol}
          className="sendBlueBtn withDisabled"
          onClick={sendForm}
          size="lg"
          variant={state.Maslol ? "success animate__animated animate__bounceIn" : "primary"}
        >
          מצא לי מקום שירות
        </Button>

        {state.Maslol && (
          <button className="btnClear" onClick={clearFields}>
            ניקוי שדות
          </button>
        )}
      </div>
    </div>
  );

  const renderMobileForm = () => (
    <div>
      <div className="line">
        <Form.Group className="inputsWithLabel selectIco maslulIco iconAfter">
          <Form.Label>מסלול</Form.Label>
          <FormControl
            as="select"
            className="inputsWithLabel selectIcoBG"
            onChange={handleChangeSelect}
            name="Maslol"
            value={state.Maslol}
          >
            <option value="">בחרו מסלול</option>
            <option disabled>----</option>
            {state.searchDataResp?.tracks?.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </FormControl>
        </Form.Group>
      </div>

      <div className="line inputs2">
        <Form.Group className="inputsWithLabel shanaIco iconAfter">
          <Form.Label>שנה</Form.Label>
          <FormControl
            as="select"
            className="inputsWithLabel selectIcoBG"
            onChange={handleChangeSelect}
            name="YEAR"
            disabled={!state.Maslol}
            value={state.YEAR}
          >
            <option value="">בחרו שנה</option>
            <option disabled>----</option>
            {state.searchDataResp?.years?.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </FormControl>
        </Form.Group>

        <Form.Group className="inputsWithLabel">
          <Form.Label>תחום</Form.Label>
          <MultiSelectReact
            disabled={!state.Maslol}
            clearMulti={state.clearMulti}
            options={state.searchDataResp?.categories || []}
            handleChangeThum={handleChangeThum}
          />
        </Form.Group>
      </div>

      <div className="line inputs2">
        <Form.Group className="inputsWithLabel ezorIco iconAfter">
          <Form.Label>איזור</Form.Label>
          <FormControl
            as="select"
            className="inputsWithLabel selectIcoBG"
            onChange={handleChangeSelect}
            name="ZoneName"
            value={state.ZoneName}
            disabled={!state.Maslol}
          >
            <option value="">בחרו</option>
            <option disabled>----</option>
            {state.searchDataResp?.zones?.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </FormControl>
        </Form.Group>

        <Form.Group className="inputsWithLabel placeIco iconAfter">
          <Form.Label>ישוב</Form.Label>
          <FormControl
            as="select"
            className="inputsWithLabel selectIcoBG"
            onChange={handleChangeSelect}
            name="City"
            value={state.City}
            disabled={!(state.Maslol && state.ZoneName)}
          >
            <option value="">בחרו</option>
            <option disabled>----</option>
            {state.searchDataResp?.cities?.map(item => (
              <option value={item.City_Value} key={item.City_Value}>
                {item.City_Key}
              </option>
            ))}
          </FormControl>
        </Form.Group>
      </div>

      <div className="line">
        <Form.Group className="inputsWithLabel searchIco">
          <Form.Label>חיפוש חופשי</Form.Label>
          <FormControl
            disabled={!state.Maslol}
            placeholder="לדוגמא: חינוך מיוחד"
            className="inputsWithLabel"
            onChange={handleChange}
            name="freeSearch"
            value={state.freeSearch}
            type="text"
          />
        </Form.Group>
      </div>

      <Button
        disabled={!state.Maslol}
        className="sendBlueBtn withDisabled"
        onClick={sendForm}
        size="lg"
        variant={state.Maslol ? "success animate__animated animate__bounceIn" : "disabled"}
      >
        מצא לי מקום שירות
      </Button>

      {state.Maslol && (
        <button className="btnClear" onClick={clearFields}>
          ניקוי שדות
        </button>
      )}
    </div>
  );

  return (
    <div className="SearchAndResults">
      <div
        style={{
          backgroundImage: 'url("https://sherut-leumi.co.il/wp-content/uploads/2023/05/Group-162537.png")',
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="SearchUpCont GreyPanel"
      >
        {state.searchDataResp && (mobile ? renderMobileForm() : renderDesktopForm())}
      </div>
    </div>
  );
};

export default SearchUpPlaces;