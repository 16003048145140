import React, { useState, useEffect } from "react";
import CustomFloatInput from "../../Components/-Helpers-/forms/CustomFloatInput";
import { RestUrls } from "../../Components/-Helpers-/config";
import { animateScroll as scroll } from "react-scroll";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import loader from "../../img/preLoader.gif";
import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";
import ModalDefaul from "../../Components/-Helpers-/ModalDefaul";
import { getAllUrlParams } from "./../../Components/-Helpers-/UrlParameters";

const Login = ({ siteInfo }) => {
  const [state, setState] = useState({
    IDNO: "",
    Password: "",
    loading: false,
    responseLogin: false,
    checkInputs: false,
    checkInputsPage: false,
    checkRadio1: false,
    checkRadio2: false,
    btnSendClass: "success"
  });

  const updateValue = (newValue) => {
    setState(prev => ({ ...prev, ...newValue }));
  };

  const sendForm = (sendRules) => {
    setState(prev => ({
      ...prev,
      checkInputs: true,
      checkInputsPage: true,
      checkRadio1: true,
      checkRadio2: true
    }));

    if (sendRules) {
      setState(prev => ({ ...prev, loading: true }));

      const sendObj = { ...state };
      delete sendObj.responseLogin;

      sendtoAdmin(
        "login",
        "register",
        sendObj,
        "responseLogin",
        "register"
      );
    } else {
      setState(prev => ({ ...prev, btnSendClass: "danger" }));
    }
  };

  const sendtoAdmin = async (url, controller, objectToSend, setStateName = "data", auth = "all") => {
    console.log("sending...");
    console.log("controller", controller);

    setState(prev => ({ ...prev, loading: true }));

    try {
      const getData = await getDataFromApi(url, objectToSend, controller, auth);
      
      setState(prev => ({ ...prev, [setStateName]: getData, loading: false }));

      if (getData.ok) {
        localStorage.setItem("userData", JSON.stringify(getData.ok));
        console.log(getData.ok, "getData.ok");
        
        const { InService, Category, FirstName, LastName, IDNO, Sex } = getData.ok;

        if (window.ReactNativeWebView !== undefined) {
          try {
            const newLog = await fetch(
              "https://webhooks.wdev.co.il/api/aguda-logs",
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  InService,
                  Category,
                  FirstName,
                  LastName,
                  IDNO,
                  Sex,
                }),
              }
            );
            console.log(newLog, "new log");
          } catch (error) {
            console.error("Error logging to webhook:", error);
          }
        }

        if (InService === false && Category !== "2") {
          window.open("/userConsole/data", "_self");
        } else if (InService === false && Category === "2") {
          window.open("/userConsole/data", "_self");
        } else if (InService === true) {
          window.open("/userConsole/clockInOut", "_self");
        } else {
          window.open("/userConsole/data", "_self");
        }
      }
    } catch (error) {
      console.error("Error in sendtoAdmin:", error);
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    const urlQuery = getAllUrlParams(window.location.href);
    const rakazId = urlQuery.rakazid || false;
    const sayeretId = urlQuery.sayeretid || false;

    if (rakazId) {
      localStorage.setItem("rakazid", rakazId);
    } else if (sayeretId) {
      localStorage.setItem("sayeretid", sayeretId);
    }
  }, []);

  const sendRules = state.IDNO && state.Password;
  const isMobile = siteInfo?.isMobile || false;
  const m_picTop = `${RestUrls.pagesPictures}register/m_registerTop.jpg?v=4`;

  return (
    <div className="firstRegister loginPage animate__animated animate__fadeIn">
      <img
        src={loader}
        alt="loader"
        className={!state.loading ? "loader" : "loader active animate__animated animate__fadeIn"}
      />

      {isMobile && <img className="topPic" src={m_picTop} alt="top" />}

      <header>
        <h1 className="boldTypeFamily">התחברות</h1>
        <p>ברוכים השבים לאגודה להתנדבות</p>
      </header>

      <div className="inputs">
        <div className="line">
          <CustomFloatInput
            name="IDNO"
            updateValue={updateValue}
            value={state.IDNO}
            placeholder="תעודת זהות"
            cssClass=""
            validationRules={{ required: true }}
            typeInput="number"
            checkInputs={state.checkInputs}
            checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
          />
        </div>

        <div className="line">
          <CustomFloatInput
            name="Password"
            updateValue={updateValue}
            value={state.Password}
            placeholder="סיסמה"
            cssClass=""
            validationRules={{ required: true }}
            typeInput="password"
            checkInputs={state.checkInputs}
            checked={() => setState(prev => ({ ...prev, checkInputs: false }))}
          />
          <div className="singIn reSendPass">
            <NavLink
              className="jumpPage"
              role="menuitem"
              onClick={() => scroll.scrollTo(0)}
              to="/reSendPass"
            >
              <span>
                <strong>שכחתי סיסמה</strong>
              </span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="text-center">
        <Button
          disabled={state.loading}
          className="sendBlueBtn"
          onClick={() => sendForm(sendRules)}
          size="lg"
          variant={sendRules ? "success animate__animated animate__bounceIn" : state.btnSendClass}
        >
          התחברות
        </Button>
        
        {window.ReactNativeWebView === undefined && (
          <div className="singIn">
            <NavLink
              className="jumpPage"
              role="menuitem"
              onClick={() => scroll.scrollTo(0)}
              to="/register"
            >
              <span>
                עדין לא רשומים? <strong>לחצו להרשמה (נרשמים חדשים)</strong>
              </span>
            </NavLink>
          </div>
        )}
      </div>

      {state.responseLogin?.error && (
        <ModalDefaul
          variant="error"
          params={{ title: "שגיאה", text: state.responseLogin.error }}
          callBack={() => setState(prev => ({ ...prev, responseLogin: false }))}
        />
      )}
    </div>
  );
};

export default Login;
