import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";
import BulletsMenu from "./BulletsMenu";

import logo from "../../img/sherut-leumi/logoNavRight.png";
import xMenu from "../../img/sherut-leumi/svg/xMenu.svg";

import { RestUrls } from "../../Components/-Helpers-/config";

import ShowSocialNetworks from "./ShowSocialNetworks";

//import { getAllUrlParams } from "./../../Components/-Helpers-/UrlParameters";

export default class NavMobileMenuContent extends Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem("userData"));

    this.state = {
      thisUser: null,
    };
    console.log(user);

    this.state = {
      loading: false,
      bounceClass: "",
      sayeretFlag: 0,
      sayarotNumber: user.Sayarot.length,
      thisUser: null,
    };
  }
  componentDidMount() {
    const { Category, InService } =
      localStorage.getItem("userData") &&
      localStorage.getItem("userData").length !== 0
        ? JSON.parse(localStorage.getItem("userData"))
        : { Category: "null", InService: "null" };
    this.setState({
      thisUser: {
        Category,
        InService,
      },
    });
    console.log(this.state.thisUser, "this.state.thisUser.InServic");
  }
  render() {
    const props = this.props.allProps;

    //const host = window.location.host;
    //const isDevUser = (host.includes("localhost:") || host.includes(".vercel.app") || host.includes("10.0.0.192:") ) ? true : false ;

    let user = JSON.parse(localStorage.getItem("userData"));
    const isUser =
      localStorage.getItem("userData") &&
      localStorage.getItem("userData").length > 0
        ? true
        : false;

    let bgDesktop = RestUrls.img;
    let currentPage = props.page;

    //for hide items
    //const urlQuery = getAllUrlParams(window.location.href);

    return (
      <div>
        <header className="m_header">
          <img src={xMenu} alt="סגירה" className="fakeClose" />

          <a
            className="logoClient"
            href="https://sherut-leumi.co.il/"
            target="_self"
            rel="noopener noreferrer"
          >
            <img src={logo} alt="" />
          </a>
        </header>

        {isUser ? (
          <div className="userData">
            <figure>
              <div
                className="userPic"
                style={{
                  backgroundImage: "url('" + bgDesktop + "default/noUser.png')",
                }}
              />
            </figure>

            <div className="textCont">
              {user.Sex === "2" ? (
                <h3>ברוכה הבאה {user.FirstName + " " + user.LastName}</h3>
              ) : (
                <h3>ברוך הבא {user.FirstName + " " + user.LastName ?? ""}</h3>
              )}
            </div>
          </div>
        ) : (
          false
        )}

        <nav className="mainMenuNav">
          <NavLink
            activeClassName={currentPage === "userData" ? "is-active" : ""}
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/userConsole/data"
          >
            <BulletsMenu name="userData" />
            <span>פרטים אישיים</span>
          </NavLink>
          {this.state.thisUser && this.state.thisUser.InService === true && (
            <NavLink
              activeClassName={
                currentPage === "clockInOutIndex" ? "is-active" : ""
              }
              role="menuitem"
              onClick={() => scroll.scrollToTop({ duration: 0 })}
              to="/userConsole/clockInOut"
            >
              <BulletsMenu name="clockInOutIndex" />
              <span>נוכחות</span>
            </NavLink>
          )}

          <NavLink
            activeClassName={currentPage === "training" ? "is-active" : ""}
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/userConsole/training"
          >
            <BulletsMenu name="userData" />
            <span>הכשרות</span>
          </NavLink>

          <NavLink
            activeClassName={currentPage === "files" ? "is-active" : ""}
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/userConsole/files"
          >
            <BulletsMenu name="files" />
            <span>מסמכים</span>
          </NavLink>
          {(this.state.thisUser && this.state.thisUser.InService === true) ||
            (this.state.thisUser &&
              this.state.thisUser.InService === false &&
              this.state.thisUser.Category === "1") ||
            (this.state.thisUser &&
              this.state.thisUser.InService === false &&
              this.state.thisUser.Category === "2" && (
                <a role="menuitem" href="/sherutPlaces" target="_self">
                  <BulletsMenu name="sherutPlaces" />
                  <span>מקומות שרות</span>
                </a>
              ))}
          <NavLink
            activeClassName={
              currentPage === "userIndex" || currentPage === "userSearch"
                ? "is-active"
                : ""
            }
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/userConsole"
          >
            <BulletsMenu
              name="bulletLev"
              sayarotNumber={this.state.sayarotNumber}
            />
            <span>סיירות</span>
          </NavLink>

          <NavLink
            activeClassName={currentPage === "editAvatar" ? "is-active" : ""}
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/userConsole/editAvatar"
          >
            <BulletsMenu name="userData" />
            <span>עריכת אווטאר</span>
          </NavLink>

          <NavLink
            activeClassName={currentPage === "contactUs" ? "is-active" : ""}
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/userConsole/contactUs"
          >
            <BulletsMenu name="contact" />
            <span>צור קשר</span>
          </NavLink>

          <a role="menuitem" href="?logout=1" target="_self">
            <BulletsMenu name="logOff" />
            <span>להתנתק</span>
          </a>
        </nav>

        <div className="credit">
          <ShowSocialNetworks />

          <a
            className="WaveProjectC"
            href="https://waveproject.co.il/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-w-hd.png"
              alt="WaveProject"
            />
          </a>
        </div>
      </div>
    );
  }
}
