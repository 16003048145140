import React, { useState, useEffect } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import getDataFromApi from '../../-Helpers-/api/getDataFromApi';

/**
 * SearchAutocompleteAndDbFocus component provides search autocomplete functionality with database integration
 * @param {Object} props - Component props
 * @param {Object} props.dbParams - Database parameters containing functionName and controller
 * @param {Function} props.submit - Callback function called when an item is selected
 * @param {Array} props.keys - Array of keys to search on in the autocomplete
 * @param {string} props.placeholder - Placeholder text for the search input
 * @returns {JSX.Element} Search autocomplete component
 */
const SearchAutocompleteAndDbFocus = ({ dbParams, submit, keys, placeholder }) => {
  const [searchItems, setSearchItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasInitialLoad, setHasInitialLoad] = useState(false);

  // Load items only on first focus
  useEffect(() => {
    if (!hasInitialLoad && dbParams) {
      const { functionName, controller } = dbParams;
      sendtoAdmin(functionName, controller, {}, 'searchItems', 'all');
      setHasInitialLoad(true);
    }
  }, [dbParams, hasInitialLoad]);

  /**
   * Handles selection of an item from search results
   * @param {Object} item - Selected item object
   */
  const handleOnSelect = (item) => {
    console.log('Search item selected:', item);
    submit(item);
  };

  /**
   * Handles focus event on search input
   * Triggers API call to fetch search items
   */
  const handleOnFocus = () => {
    if (!hasInitialLoad) {
      console.log('Search input focused, fetching items with params:', dbParams);
      const { functionName, controller } = dbParams;
      sendtoAdmin(functionName, controller, {}, 'searchItems', 'all');
      setHasInitialLoad(true);
    }
  };

  /**
   * Makes API call to fetch search items from backend
   * @param {string} url - API endpoint URL
   * @param {string} controller - API controller name
   * @param {Object} objectToSend - Request payload
   * @param {string} setStateName - Name of state to update
   * @param {string} auth - Authorization level
   */
  const sendtoAdmin = async (url, controller, objectToSend, setStateName = 'data', auth = 'all') => {
    if (loading) return; // Prevent multiple simultaneous requests
    
    setError(null);
    setLoading(true);

    try {
      const response = await getDataFromApi(url, objectToSend, controller, auth);
      
      if (response?.options) {
        setSearchItems(response.options);
      } else {
        console.warn('Invalid response format:', response);
        setSearchItems([]);
      }
    } catch (error) {
      console.error('Error fetching search items:', error);
      setError(error.message);
      setSearchItems([]); // Clear items on error for consistent behavior
    } finally {
      setLoading(false);
    }
  };

  return (
    <ReactSearchAutocomplete
      fuseOptions={{ 
        keys,
        threshold: 0.3, // Lower threshold for stricter matching
        distance: 100,  // Increase distance for better partial matches
        shouldSort: true // Ensure results are sorted by relevance
      }}
      placeholder={loading ? 'טוען...' : error ? 'שגיאה בטעינה' : placeholder}
      items={searchItems}
      onFocus={handleOnFocus}
      onSelect={handleOnSelect}
      showClear={false}
      maxResults={200}
      styling={{
        zIndex: 2, // Ensure dropdown appears above other elements
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)' // Better visibility for dropdown
      }}
      autoFocus={false} // Prevent autofocus to avoid immediate loading
      onSearch={(string, results) => {
        // If no results found and we haven't loaded items yet, trigger load
        if (results.length === 0 && searchItems.length === 0) {
          handleOnFocus();
        }
      }}
    />
  );
};

export default SearchAutocompleteAndDbFocus;
