import React, { useState, useEffect, useCallback } from "react";
import SearchUpPlaces from "./includes/SearchUpPlaces";
import PropTypes from 'prop-types';
import axios from "axios";
import { RestUrls } from "../../Components/-Helpers-/config";

function MekomotSherutSearchOnly({ isMobile = false }) {
  const [clearMulti, setClearMulti] = useState(false);
  const [state, setState] = useState({
    loading: false,
    searchOptions: {
      cities: [],
      zones: [],
      years: [], 
      categories: [],
      tracks: [],
      currentYear: "",
    }
  });

  const fetchSearchParams = useCallback(async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      
      const response = await axios.post(
        `${RestUrls.baseApiUrl}mekSherut/getMekSherutSearchParams`,
        {
          auth: "mekSherut",
          token: RestUrls.Code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.data) {
        setState(prev => ({
          ...prev,
          searchOptions: {
            cities: response.data.cities || [],
            zones: response.data.zones || [],
            years: response.data.years || [],
            categories: response.data.categories || [],
            tracks: response.data.tracks || [],
            currentYear: response.data.currentYear || "",
          },
          loading: false,
        }));
      }
    } catch (error) {
      console.error("Error fetching search parameters:", error);
      setState(prev => ({ ...prev, loading: false }));
    }
  }, []);

  useEffect(() => {
    fetchSearchParams();
  }, [fetchSearchParams]);

  const updateStateFromSearch = useCallback((searchState) => {
    let urlPath = `?iframe=1`;

    // Helper function to handle values
    const cleanValue = (value) => {
      if (value === true || value === 'true') return '';
      if (!value) return '';
      return value;
    };

    // Helper function to handle arrays
    const cleanArray = (arr) => {
      if (!Array.isArray(arr)) return '';
      return arr
        .map(v => v === true || v === 'true' ? '' : v)
        .filter(Boolean)
        .join(',');
    };

    urlPath += `&maslol=${encodeURIComponent(cleanValue(searchState.Maslol))}`;
    urlPath += `&city=${encodeURIComponent(cleanValue(searchState.City))}`;
    urlPath += `&thum=${encodeURIComponent(cleanArray(searchState.Thum))}`;
    urlPath += `&year=${encodeURIComponent(cleanValue(searchState.YEAR))}`;
    urlPath += `&zonename=${encodeURIComponent(cleanValue(searchState.ZoneName))}`;

    const pageUrl = "https://app.sherut-leumi.co.il/";

    if (searchState.clearMulti && searchState.clearMulti !== clearMulti) {
      setClearMulti(searchState.clearMulti);
    } else {
      window.open(pageUrl + urlPath, "_blank");
    }
  }, [clearMulti]);

  return (
    <div className={`${isMobile ? "Mobile" : ""} MekomotSherutIndex`}>
      <div className="SherutPlacesIndex">
        <SearchUpPlaces
          currentState={{
            freeSearch: "",
            Maslol: "",
            Thum: [],
            YEAR: state.searchOptions.years?.[0]?.value || "",
            ZoneName: "",
            City: "",
            searchOptions: state.searchOptions
          }}
          mobile={isMobile}
          closeSearch={() => {}}
          updateSearch={updateStateFromSearch}
          clearMulti={clearMulti}
        />
      </div>
    </div>
  );
}

MekomotSherutSearchOnly.propTypes = {
  isMobile: PropTypes.bool
};

export default MekomotSherutSearchOnly;
