import React from "react";
import { RestUrls } from "../../../Components/-Helpers-/config";

import rakaz from "./../../../img/sherut-leumi/svg/sherutPlaces/card/moreInfo/1.svg";
import phone from "./../../../img/sherut-leumi/svg/sherutPlaces/card/moreInfo/2.svg";
import whatsapp from "./../../../img/sherut-leumi/svg/sherutPlaces/card/moreInfo/3.svg";
import shituf from "./../../../img/sherut-leumi/svg/sherutPlaces/card/moreInfo/4.svg";

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const ShowMoreData = ({ item, isMobile }) => {
  const urlShare = `${RestUrls.appUrl}/sherutPlaces?id=${item.id}`;
  const textShare = `${item.MOSADNA} - האגודה להתנדבות`;

  const isLandline = (phone) => {
    const landlinePrefixes = ["02", "03", "04", "08", "09", "072", "073", "074", "076", "077", "078"];
    return landlinePrefixes.some(prefix => phone?.startsWith(prefix));
  };
  


  return (
    <div>
      <h3>פרטים נוספים:</h3>

      <div className="moreDataPlaces">
        {item.MAS_NOTES && (
          <article>
            <h4>תאור המוסד:</h4>
            <p>{item.MAS_NOTES}</p>
          </article>
        )}

        {item.TAFKID && (
          <article>
            <h4>הגדרות תפקיד:</h4>
            <p>{item.TAFKID}</p>
          </article>
        )}
      </div>

      <ul className="iconsLine fullLine">
        <li>
          <img src={rakaz} alt="" />
          <span>רכז/ת: </span> <span>{item.Rak_Value}</span>
          {item.Rak_Key && item.sayarotRakazCount > 0 && (
            <a
              href={`/userConsole/?rakazid=${item.Rak_Key}`}
              target="_blank"
              className="goSayarot bounce3"
              rel="noopener noreferrer"
            >
              צפו בסיירות שלי &raquo;
            </a>
          )}
        </li>

        {item.Rak_Phone && (
          <li>
            {isMobile ? (
              <div>
                <a href={`tel:${item.Rak_Phone}`}>
                  <img src={phone} alt="" />
                  <span>{item.Rak_Phone}</span>
                </a>
              </div>
            ) : (
              <div>
                <img src={phone} alt="" />
                <span>{item.Rak_Phone}</span>
              </div>
            )}
          </li>
        )}

        {item.Rak_Phone && !isLandline(item.Rak_Phone) && (
          <li>
            <a
              href={`https://api.whatsapp.com/send?phone=972${item.Rak_Phone.replace("-", "")}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp} alt="" />
              <span>שיחה עם הרכז/ת</span>
            </a>
          </li>
        )}

        <li>
          <img src={shituf} alt="" />
          <span>שיתוף</span>

          <div className="shares">
            <EmailShareButton url={urlShare} subject={textShare}>
              <EmailIcon size={32} round />
            </EmailShareButton>

            <WhatsappShareButton
              url={urlShare}
              title={textShare}
              windowWidth={1200}
              windowHeight={800}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <TelegramShareButton url={urlShare} title={textShare}>
              <TelegramIcon size={32} round />
            </TelegramShareButton>

            <TwitterShareButton url={urlShare} title={textShare}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <FacebookShareButton
              url={encodeURI(urlShare)}
              quote={item.MAS_NOTES}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ShowMoreData;
