import React, { Component } from "react";
import { Button } from "react-bootstrap";
import getDataFromApi from "../../../Components/-Helpers-/api/getDataFromApi";
import loader from "../../../img/preLoader.gif";
import CustomFloatInput from "../../../Components/-Helpers-/forms/CustomFloatInput";
import man from "../../../img/sherut-leumi/svg/man.svg";
import woman from "../../../img/sherut-leumi/svg/woman.svg";
import ModalDefaul from "../../../Components/-Helpers-/ModalDefaul";
import { toast } from "react-toastify";

function get17YearsAgo() {
  const options = {
    weekday: "long",
    year: "numeric", 
    month: "long",
    day: "numeric",
  };
  const date = new Date();
  date.toLocaleDateString("he-IL", options);
  date.setFullYear(date.getFullYear() - 17);
  return date.toISOString().substr(0, 10);
}

export default class PersonalInformation extends Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem("userData"));
    let userApi = this.props.userData;

    // Initialize state with empty strings if values are null
    this.state = {
      idno: user.IDNO || '',
      SessionKey: user.SessionKey || '',
      FirstName: userApi.firstname || '',
      LastName: userApi.lastname || '',
      Mobile: userApi.mobile || '',
      BirthDate: userApi.birthdate || '',
      BirthDateText: userApi.BirthDateText || '',
      is_tzForm: true,
      CityCode: userApi.city || '',
      Email: userApi.email || '',
      PrvSchool: userApi.prvschool || '',
      PrvSchoolText: userApi.PrvSchoolText || '',
      sex: userApi.sex === "1" ? "man" : "woman",
      loading: false,
      btnSendClass: "success",
      checkInputsPage: false,
      checkInputs: false,
      responseEditRegister: false
    };
  }

  sendForm = (sendRules) => {
    this.setState({
      checkInputs: true,
      checkInputsPage: true,
    });

    if (sendRules) {
      this.setState({
        loading: true,
      });

      const sendObj = {...this.state};
      delete sendObj.responseEditRegister;
      delete sendObj.loading;
      delete sendObj.btnSendClass;
      delete sendObj.checkInputs;
      delete sendObj.checkInputsPage;

      this.sendtoAdmin(
        "editUserData",
        "users",
        sendObj,
        "responseEditRegister",
        "users"
      );
    } else {
      toast.error("נא למלא שדות חובה");
      this.setState({
        btnSendClass: "danger",
      });
    }
  };

  sendtoAdmin = (url, controller, objectToSend, setStateName = "data", auth = "all") => {
    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);
    getData.then(getData => {
      this.setState({ [setStateName]: getData, loading: false }, () => {
        if (getData.newUserData) {
          let user = JSON.parse(localStorage.getItem("userData"));
          user["FirstName"] = getData.newUserData.firstname;
          user["LastName"] = getData.newUserData.lastname;
          user["Sex"] = getData.newUserData.sex;
          localStorage.setItem("userData", JSON.stringify(user));
        }
      });
    });
  };

  updateValue = (newValue) => {
    this.setState(newValue);
  };

  render() {
    const sendRules =
      this.state.sex &&
      this.state.FirstName &&
      this.state.LastName &&
      this.state.idno &&
      this.state.Mobile &&
      this.state.BirthDate &&
      this.state.Email;

    const isMobile = this.props.isMobile || false;

    return (
      <div>
        <div className="firstRegister animate__animated animate__fadeIn">
          <img
            src={loader}
            alt="loader"
            className={
              !this.state.loading
                ? "loader"
                : "loader active animate__animated animate__fadeIn"
            }
          />

          <div className="inputs">
            <div className="rows2 clear line">
              <CustomFloatInput
                disabled={this.state.is_tzForm}
                name="FirstName"
                updateValue={this.updateValue}
                value={this.state.FirstName}
                placeholder="שם פרטי"
                cssClass=""
                validationRules={{ required: true, minlength: 2, alpha: true }}
                typeInput="text"
                checkInputs={this.state.checkInputs}
                checked={() => this.setState({ checkInputs: false })}
              />
              <CustomFloatInput
                disabled={this.state.is_tzForm}
                name="LastName"
                updateValue={this.updateValue}
                value={this.state.LastName}
                placeholder="שם משפחה"
                cssClass=""
                validationRules={{ required: true, minlength: 2, alpha: true }}
                typeInput="text"
                checkInputs={this.state.checkInputs}
                checked={() => this.setState({ checkInputs: false })}
              />
            </div>

            <div className="rows2 clear line">
              <CustomFloatInput
                disabled={this.state.is_tzForm}
                name="idno"
                updateValue={this.updateValue}
                value={this.state.idno}
                placeholder="תעודת זהות"
                cssClass=""
                validationRules={{ required: true, tz: true }}
                typeInput="text"
                checkInputs={this.state.checkInputs}
                checked={() => this.setState({ checkInputs: false })}
              />
              <CustomFloatInput
                name="Mobile"
                updateValue={this.updateValue}
                value={this.state.Mobile}
                placeholder="טלפון נייד"
                cssClass=""
                validationRules={{ required: true, phone: true }}
                typeInput="number"
                checkInputs={this.state.checkInputs}
                checked={() => this.setState({ checkInputs: false })}
              />
            </div>

            <div className="rows2 clear line">
              <CustomFloatInput
                disabled={this.state.is_tzForm}
                isMobile={isMobile}
                name="BirthDate"
                updateValue={this.updateValue}
                value={this.state.BirthDate}
                placeholder="תאריך לידה"
                cssClass=""
                validationRules={{ required: true }}
                typeInput="date"
                dateText={this.state.BirthDateText}
                checkInputs={this.state.checkInputs}
                startDate={get17YearsAgo()}
                checked={() => this.setState({ checkInputs: false })}
              />
              <CustomFloatInput
                name="Email"
                updateValue={this.updateValue}
                value={this.state.Email}
                placeholder='דוא"ל'
                cssClass=""
                validationRules={{ required: true, email: true }}
                typeInput="text"
                checkInputs={this.state.checkInputs}
                checked={() => this.setState({ checkInputs: false })}
              />
            </div>

            <div className="line selectButtons">
              <label
                className={
                  this.state.checkInputsPage && !this.state.sex
                    ? "errorClass"
                    : "1"
                }
              >
                מין
              </label>

              <div className="buttonsCont">
                <button
                  disabled={this.state.is_tzForm}
                  onClick={() =>
                    this.setState({ sex: "man", checkInputsPage: false })
                  }
                  className={
                    this.state.sex === "man" ? "choseBtn selected" : "choseBtn"
                  }
                >
                  <img src={man} alt="זכר" />
                  <span>זכר</span>
                </button>

                <button
                  disabled={this.state.is_tzForm}
                  onClick={() =>
                    this.setState({ sex: "woman", checkInputsPage: false })
                  }
                  className={
                    this.state.sex === "woman"
                      ? "choseBtn selected"
                      : "choseBtn"
                  }
                >
                  <img src={woman} alt="נקבה" />
                  <span>נקבה</span>
                </button>
              </div>
            </div>

            <div className="text-center">
              <br />
              <br />
              <Button
                className="sendBlueBtn"
                onClick={() => this.sendForm(sendRules)}
                size="lg"
                variant={
                  sendRules
                    ? "success animate__animated animate__bounceIn"
                    : this.state.btnSendClass
                }
              >
                שמירת שינויים
              </Button>
            </div>
          </div>

          {this.state.responseEditRegister &&
          this.state.responseEditRegister.error ? (
            <ModalDefaul
              variant="error"
              params={{
                title: "שגיאה",
                text: this.state.responseEditRegister.error,
              }}
              callBack={() => this.setState({ responseEditRegister: false })}
            />
          ) : null}

          {this.state.responseEditRegister &&
          this.state.responseEditRegister.ok ? (
            <ModalDefaul
              params={{
                title: "תודה רבה",
                text: this.state.responseEditRegister.ok,
              }}
              callBack={() => this.setState({ responseEditRegister: false })}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
