import React, { useState, useEffect } from "react";
import loader from "../../img/preLoader.gif";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";
import CustomFloatInput from "../../Components/-Helpers-/forms/CustomFloatInput";
import FloatInputPassword from "../../Components/-Helpers-/forms/FloatInputPassword";

import man from "../../img/sherut-leumi/svg/man.svg";
import woman from "../../img/sherut-leumi/svg/woman.svg";

import { RestUrls } from "../../Components/-Helpers-/config";

import { animateScroll as scroll } from "react-scroll";
import { NavLink } from "react-router-dom";

import ModalDefaul from "../../Components/-Helpers-/ModalDefaul";

const get17YearsAgo = () => {
  const options = {
    weekday: "long",
    year: "numeric", 
    month: "long",
    day: "numeric"
  };
  const date = new Date();
  date.toLocaleDateString("he-IL", options);
  date.setFullYear(date.getFullYear() - 17);
  return date.toISOString().substr(0, 10);
};

const SmallForm = (props) => {
  const [formState, setFormState] = useState({
    responseNewRegister: false,
    sex: false,
    takanon: false,
    rishum: false,
    loading: false,
    btnSendClass: "success",
    checkInputs: false,
    checkInputsPage: false,
    checkRadio1: false,
    checkRadio2: false,
    dataApi: false
  });

  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    IDNO: "",
    Mobile: "",
    MobileStart: "",
    BirthDate: "",
    CityCode: "",
    Email: "",
    Category: "",
    PrvSchool: "",
    YearYad: ""
  });

  const updateValue = (newValue) => {
    setFormData(prev => ({...prev, ...newValue}));
  };

  const validateForm = () => {
    let isValid = true;
    let errorMessage = '';

    // Check required fields
    if (!formState.sex) {
      errorMessage = 'נא לבחור מין';
      isValid = false;
    }
    if (!formState.takanon) {
      errorMessage = 'נא לאשר את התקנון';
      isValid = false;
    }
    if (!formState.rishum) {
      errorMessage = 'נא לאשר את הרישום למאגר';
      isValid = false;
    }

    // Check if any required field is empty
    const requiredFields = {
      FirstName: 'שם פרטי',
      LastName: 'שם משפחה',
      IDNO: 'תעודת זהות',
      Mobile: 'טלפון נייד',
      MobileStart: 'קידומת',
      BirthDate: 'תאריך לידה',
      CityCode: 'עיר/ישוב',
      Email: 'דואר אלקטרוני',
      Category: 'מסלול התנדבות',
      PrvSchool: 'בית ספר',
      YearYad: 'שנת שירות'
    };

    const missingFields = [];
    Object.entries(requiredFields).forEach(([key, label]) => {
      if (!formData[key]) {
        missingFields.push(label);
      }
    });

    if (missingFields.length > 0) {
      errorMessage = `שדות חובה חסרים: ${missingFields.join(', ')}`;
      isValid = false;
    }

    // Update form state for validation
    setFormState(prev => ({
      ...prev,
      checkInputs: true,
      checkInputsPage: true,
      checkRadio1: !formState.takanon,
      checkRadio2: !formState.rishum,
      responseNewRegister: errorMessage ? { error: errorMessage } : false,
      btnSendClass: errorMessage ? "danger" : "success"
    }));

    return isValid;
  };

  const handleApiError = (getData) => {
    let errorMessage = '';
    let errorDetails = [];

    // Handle different error scenarios
    if (getData.ok?.headerStatus === 400) {
      if (getData.ok.responseClient?.ErrorMessage) {
        errorMessage = getData.ok.responseClient.ErrorMessage;
      } else if (getData.ok.error) {
        errorMessage = getData.ok.error;
      }

      // Check fields for specific issues
      if (getData.ok.fields) {
        const fieldLabels = {
          FirstName: 'שם פרטי',
          LastName: 'שם משפחה',
          IDNO: 'תעודת זהות',
          Mobile: 'טלפון נייד',
          MobileStart: 'קידומת',
          BirthDate: 'תאריך לידה',
          CityCode: 'עיר/ישוב',
          Email: 'דואר אלקטרוני',
          Category: 'מסלול התנדבות',
          PrvSchool: 'בית ספר',
          YearYad: 'שנת שירות',
          sex: 'מין'
        };

        const missingFields = [];
        const invalidFields = [];

        Object.entries(getData.ok.fields).forEach(([key, value]) => {
          const label = fieldLabels[key] || key;
          if (!value) {
            missingFields.push(label);
          } else if (value === 'invalid') {
            invalidFields.push(label);
          }
        });

        if (missingFields.length > 0) {
          errorDetails.push(`שדות חובה חסרים: ${missingFields.join(', ')}`);
        }
        if (invalidFields.length > 0) {
          errorDetails.push(`שדות לא תקינים: ${invalidFields.join(', ')}`);
        }
      }
    } else if (getData.error) {
      errorMessage = getData.error;
    }

    // Combine error messages
    const finalErrorMessage = [
      errorMessage,
      ...errorDetails
    ].filter(Boolean).join('\n');

    return finalErrorMessage || 'אנא בדקו את הפרטים שהוזנו';
  };

  const sendForm = () => {
    if (!validateForm()) {
      return;
    }

    setFormState(prev => ({...prev, loading: true}));
    const sendObj = {...formData, sex: formState.sex};
    sendtoAdmin(
      "newRegister",
      "register", 
      sendObj,
      "responseNewRegister",
      "register"
    );
  };

  const sendtoAdmin = async (url, controller, objectToSend, setStateName = "data", auth = "all") => {
    setFormState(prev => ({...prev, loading: true}));

    try {
        const getData = await getDataFromApi(url, objectToSend, controller, auth);
        console.log('Registration response:', getData);
        
        // Check for successful registration based on responseClient.Result
        if (getData?.responseClient?.Result === "Success") {
            // Store user data if available
            if (getData.volunteer) {
                localStorage.setItem("userData", JSON.stringify({
                    ...getData.volunteer,
                    SessionKey: getData.SessionKey
                }));
            }

            // Show success message
            toast.success('ההרשמה הושלמה בהצלחה!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            // Redirect to login page after success message
            setTimeout(() => {
                window.open("/login", "_self");
            }, 2000);

            setFormState(prev => ({
                ...prev,
                loading: false,
                rishum: true,
                takanon: true,
                btnSendClass: "success"
            }));
        } else if (getData?.headerStatus === 400 || getData?.responseClient?.ErrorMessage) {
            // Handle any errors
            const errorMessage = handleApiError(getData);
            setFormState(prev => ({
                ...prev,
                responseNewRegister: {
                    error: errorMessage
                },
                btnSendClass: "danger",
                loading: false
            }));
        } else {
            // Unexpected response format
            console.error('Unexpected response format:', getData);
            setFormState(prev => ({
                ...prev,
                responseNewRegister: {
                    error: 'תקלה בתהליך ההרשמה. אנא נסו שוב.'
                },
                btnSendClass: "danger",
                loading: false
            }));
        }
    } catch (error) {
        console.error("API Error:", error);
        setFormState(prev => ({
            ...prev, 
            loading: false,
            responseNewRegister: {
                error: 'אירעה שגיאה בתקשורת עם השרת. אנא נסו שוב מאוחר יותר.'
            },
            btnSendClass: "danger"
        }));
    }
  };

  const getDataApi = () => {
    sendtoAdmin2("hebrewYears", "register", {}, "dataApi", "all");
  };

  const sendtoAdmin2 = async (url, controller, objectToSend, setStateName = "data", auth = "all") => {
    setFormState(prev => ({...prev, loading: true}));

    try {
      const getData = await getDataFromApi(url, objectToSend, controller, auth);
      
      setFormState(prev => {
        const newState = {...prev, [setStateName]: getData, loading: false};
        
        if (getData?.years) {
          const years = getData.years;
          newState.dataApi = {
            ...getData,
            years: [
              { id: years.current.substring(1), value: "שנה זו" },
              { id: years.nextYear.substring(1), value: "שנה הבאה" },
              { id: years.year2.substring(1), value: "עוד שנתיים" }
            ]
          };
        }
        
        return newState;
      });
    } catch (error) {
      console.error("API Error:", error);
      setFormState(prev => ({...prev, loading: false}));
    }
  };

  useEffect(() => {
    if (!formState.dataApi) {
      getDataApi();
    }
  }, [formState.dataApi]);

  // Log validation errors
  useEffect(() => {
    if (formState.responseNewRegister?.error) {
      console.error('Form Validation Error:', {
        error: formState.responseNewRegister.error,
        formData,
        formState: {
          sex: formState.sex,
          rishum: formState.rishum,
          takanon: formState.takanon
        }
      });
    }
  }, [formState.responseNewRegister, formData, formState.sex, formState.rishum, formState.takanon]);

  // Log form state changes
  useEffect(() => {
    if (formState.checkInputs) {
      console.log('Form State Updated:', {
        formData,
        formState: {
          sex: formState.sex,
          rishum: formState.rishum,
          takanon: formState.takanon,
          checkInputs: formState.checkInputs,
          checkInputsPage: formState.checkInputsPage,
          checkRadio1: formState.checkRadio1,
          checkRadio2: formState.checkRadio2
        }
      });
    }
  }, [formState, formData]);

  const kidomot = [
    { id: "050", value: "050" },
    { id: "051", value: "051" },
    { id: "052", value: "052" },
    { id: "053", value: "053" },
    { id: "054", value: "054" },
    { id: "055", value: "055" },
    { id: "056", value: "056" },
    { id: "057", value: "057" },
    { id: "058", value: "058" },
    { id: "059", value: "059" },
    { id: "072", value: "072" },
    { id: "073", value: "073" },
    { id: "074", value: "074" },
    { id: "076", value: "076" },
    { id: "077", value: "077" },
    { id: "078", value: "078" }
  ];

  const CategoryOptions = [
    { id: "1", value: 'חילוני' },
    { id: "2", value: "דתי" },
    { id: "3", value: "מיעוטים" }
  ];

  const isMobile = props.siteInfo?.isMobile || false;
  const m_picTop = RestUrls.pagesPictures + "register/m_registerTop.jpg?v=2";

  return (
    <div className="firstRegister animate__animated animate__fadeIn">
      {isMobile && <img className="topPic" src={m_picTop} alt="top" />}

      <img
        src={loader}
        alt="loader"
        className={
          !formState.loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      <header>
        <h1 className="boldTypeFamily">הרשמה לאתר</h1>
        <p>בכדי להתחיל בתהליך השירות הלאומי עלייך להרשם לאתר</p>
      </header>

      <div className="inputs">
        <div className="rows2 clear line">
          <CustomFloatInput
            name="FirstName"
            updateValue={updateValue}
            value={formData.FirstName}
            noEnglish={true}
            placeholder="שם פרטי"
            cssClass=""
            validationRules={{ required: true, minlength: 2, alpha: true }}
            typeInput="text"
            checkInputs={formState.checkInputs}
            checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
          />
          <CustomFloatInput
            name="LastName"
            updateValue={updateValue}
            value={formData.LastName}
            noEnglish={true}
            placeholder="שם משפחה"
            cssClass=""
            validationRules={{ required: true, minlength: 2, alpha: true }}
            typeInput="text"
            checkInputs={formState.checkInputs}
            checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
          />
        </div>

        <div className="rows3 clear line lineTZPhone">
          <CustomFloatInput
            name="IDNO"
            updateValue={updateValue}
            value={formData.IDNO}
            placeholder="תעודת זהות"
            cssClass=""
            validationRules={{ required: true, tz: true }}
            typeInput="number"
            checkInputs={formState.checkInputs}
            checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
          />

          <div className="phoneInputs">
            <CustomFloatInput
              name="MobileStart"
              selectOptions={kidomot}
              updateValue={updateValue}
              value={formData.MobileStart}
              placeholder="קידומת"
              cssClass=""
              validationRules={{ required: true }}
              typeInput="select"
              checkInputs={formState.checkInputs}
              checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
            />
            <CustomFloatInput
              name="Mobile"
              updateValue={updateValue}
              value={formData.Mobile}
              placeholder="טלפון נייד"
              cssClass=""
              validationRules={{
                required: true,
                minlength: 7,
                maxlength: 7,
                numbers: true,
              }}
              typeInput="text"
              checkInputs={formState.checkInputs}
              checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
            />
          </div>
        </div>

        <div className="rows2 clear line">
          <CustomFloatInput
            isMobile={isMobile}
            name="BirthDate"
            updateValue={updateValue}
            value={formData.BirthDate}
            startDate={get17YearsAgo()}
            placeholder="תאריך לידה"
            cssClass=""
            validationRules={{ required: true }}
            typeInput="date"
            checkInputs={formState.checkInputs}
            checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
          />
          <CustomFloatInput
            name="Email"
            updateValue={updateValue}
            value={formData.Email}
            placeholder='דוא"ל'
            cssClass=""
            validationRules={{ required: true, email: true }}
            typeInput="text"
            checkInputs={formState.checkInputs}
            checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
          />
        </div>

        <div className="rows2 clear line">
          <CustomFloatInput
            name="CityCode"
            dbParams={{
              function: "getCities4Select",
              controller: "app",
              valueField: "id",
            }}
            updateValue={updateValue}
            value={formData.CityCode}
            placeholder="עיר / ישוב"
            cssClass=""
            zIndexClass="topZindex"
            validationRules={{ required: true }}
            typeInput="searchDB"
            checkInputs={formState.checkInputs}
            checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
          />
          <CustomFloatInput
            name="PrvSchool"
            dbParams={{
              function: "getSchools4Select",
              controller: "app",
              valueField: "id",
            }}
            updateValue={updateValue}
            value={formData.PrvSchool}
            placeholder="בית ספר"
            cssClass=""
            validationRules={{ required: true }}
            typeInput="searchDB"
            checkInputs={formState.checkInputs}
            checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
          />
        </div>

        <div className="line selectButtons">
          <label
            className={
              formState.checkInputsPage && !formState.sex
                ? "errorClass"
                : "1"
            }
          >
            מין
          </label>

          <div className="buttonsCont">
            <button
              onClick={() => setFormState(prev => ({...prev, sex: "man", checkInputsPage: false}))}
              className={formState.sex === "man" ? "choseBtn selected" : "choseBtn"}
            >
              <img src={man} alt="זכר" />
              <span>זכר</span>
            </button>

            <button
              onClick={() => setFormState(prev => ({...prev, sex: "woman", checkInputsPage: false}))}
              className={formState.sex === "woman" ? "choseBtn selected" : "choseBtn"}
            >
              <img src={woman} alt="נקבה" />
              <span>נקבה</span>
            </button>
          </div>
        </div>

        <div className="rows2 clear line">
          <CustomFloatInput
            name="Category"
            selectOptions={CategoryOptions}
            updateValue={updateValue}
            value={formData.Category}
            placeholder="בחירת מסלול התנדבות"
            cssClass=""
            validationRules={{ required: true }}
            typeInput="select"
            checkInputs={formState.checkInputs}
            checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
          />
          {formState.dataApi?.years?.length > 0 && (
            <CustomFloatInput
              name="YearYad"
              selectOptions={formState.dataApi.years}
              updateValue={updateValue}
              value={formData.YearYad}
              placeholder="שנת שירות"
              cssClass=""
              validationRules={{ required: true }}
              typeInput="select"
              checkInputs={formState.checkInputs}
              checked={() => setFormState(prev => ({...prev, checkInputs: false}))}
            />
          )}
        </div>

        <div className="line checkboxes">
          <Form.Group className="checkBoxCont" controlId="formBasicCheckbox">
            <Form.Check.Input
              type="radio"
              isInvalid={formState.checkRadio1 && !formState.takanon}
              onChange={() => {
                setFormState(prev => ({...prev, takanon: true, checkRadio1: false}));
              }}
            />

            <Form.Check.Label>
              <span>קראתי את </span>
              <a
                href="https://sherut-leumi.co.il/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F-%D7%90%D7%AA%D7%A8/"
                target="_blank"
                rel="noreferrer"
              >
                התקנון
              </a>
              <span> אני מאשר/ת את הכתוב</span>
            </Form.Check.Label>
          </Form.Group>

          <Form.Group className="checkBoxCont" controlId="formBasicCheckbox2">
            <Form.Check.Input
              type="radio"
              isInvalid={formState.checkRadio2 && !formState.rishum}
              onChange={() => {
                setFormState(prev => ({...prev, rishum: true, checkRadio2: false}));
              }}
            />

            <Form.Check.Label>
              <span>
                אני מאשר/ת רישום למאגר לקוחות ואני מסכימ/ה לקבל דיוור ללא
                המילה פרסומות בכותרות
              </span>
            </Form.Check.Label>
          </Form.Group>
        </div>

        <div className="text-center">
          <Button
            className="sendBlueBtn"
            onClick={sendForm}
            size="lg"
            variant={formState.btnSendClass}
          >
            לסיום ההרשמה וקבלת סיסמא בSMS
          </Button>

          <div className="singIn">
            <NavLink
              className="jumpPage"
              role="menuitem"
              onClick={() => scroll.scrollTo(0)}
              to="/login"
            >
              <span>
                כבר רשומים? <strong>לחצו להתחברות</strong>
              </span>
            </NavLink>
          </div>
        </div>
      </div>

      {formState.responseNewRegister?.error && (
        <ModalDefaul
          variant="error"
          params={{
            title: "שגיאה",
            text: formState.responseNewRegister.error,
          }}
          callBack={() => setFormState(prev => ({...prev, responseNewRegister: false}))}
        />
      )}
    </div>
  );
};

export default SmallForm;
