import React, { useState, useEffect, useCallback } from "react";
import { Accordion } from "react-bootstrap";
import { RestUrls } from "../../Components/-Helpers-/config";
import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";
import loader from "../../img/preLoader.gif";
import PersonalInformation from "./userData/PersonalInformation";
import Address from "./userData/Address";
import Emergency from "./userData/Emergency";
import Studies from "./userData/Studies";
import Sherut from "./userData/Sherut";
import pInfo from "../../img/sherut-leumi/svg/userData/pInfo.svg";
import ic2 from "../../img/sherut-leumi/svg/userData/2.svg";
import ic3 from "../../img/sherut-leumi/svg/userData/3.svg";
import ic4 from "../../img/sherut-leumi/svg/userData/4.svg";
import ic5 from "../../img/sherut-leumi/svg/userData/5.svg";
import AvatarPratimMobilePic from "./closeApp/AvatarPratimMobilePic";

const UserConsoleDataIndex = ({ siteInfo }) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const getUserData = useCallback(() => {
    let cachedUserData;
    let cachedTimestamp;
    try {
      cachedUserData = localStorage.getItem("cachedUserData");
      cachedTimestamp = localStorage.getItem("cachedUserDataTimestamp");
    } catch (error) {
      console.error("Error accessing localStorage:", error);
    }

    const currentTime = new Date().getTime();
    const fourHoursInMilliseconds = 4 * 60 * 60 * 1000;

    if (cachedUserData && cachedTimestamp && (currentTime - parseInt(cachedTimestamp) < fourHoursInMilliseconds)) {
      try {
        const parsedData = JSON.parse(cachedUserData);
        // Ensure userData exists and has required fields
        if (parsedData && parsedData.userData) {
          setUserData(parsedData);
          setLoading(false);
        } else {
          fetchUserData();
        }
      } catch (error) {
        console.error("Error parsing cachedUserData:", error);
        fetchUserData();
      }
    } else {
      fetchUserData();
    }
  }, []);

  const fetchUserData = () => {
    let user;
    try {
      user = JSON.parse(localStorage.getItem("userData"));
    } catch (error) {
      console.error("Error accessing or parsing userData from localStorage:", error);
      return;
    }

    if (user && user.IDNO && user.SessionKey) {
      const sendObj = {
        idno: user.IDNO,
        SessionKey: user.SessionKey,
      };
      sendtoAdmin("getUserData", "users", sendObj, "userData", "users");
    } else {
      console.error("Invalid user data in localStorage");
    }
  };

  const sendtoAdmin = useCallback((url, controller, objectToSend, setStateName = "data", auth = "all") => {
    setLoading(true);
    getDataFromApi(url, objectToSend, controller, auth)
      .then((data) => {
        // Validate data before setting state
        if (data && data.userData) {
          // Initialize empty strings for null values
          data.userData = Object.keys(data.userData).reduce((acc, key) => {
            acc[key] = data.userData[key] === null ? '' : data.userData[key];
            return acc;
          }, {});
          
          setUserData(data);
          try {
            localStorage.setItem("cachedUserData", JSON.stringify(data));
            localStorage.setItem("cachedUserDataTimestamp", new Date().getTime().toString());
          } catch (error) {
            console.error("Error saving to localStorage:", error);
          }
        } else {
          console.error("Invalid data format received");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!userData) {
      getUserData();
    }
  }, [userData, getUserData]);

  const isMobile = siteInfo?.isMobile || false;
  const topPic = isMobile
    ? `${RestUrls.pagesPictures}/register/m_registerTop.jpg?v=2`
    : `${RestUrls.pagesPictures}/search/searchTop.jpg?v=1`;

  return (
    <div className="DataSection">
      <img className="topPic full_width" src={topPic} alt="top" />
      {isMobile && <AvatarPratimMobilePic />}
      <header>
        <h1>פרטים אישיים</h1>
        <p>תודה שבחרת להתנדב איתנו!</p>
      </header>
      <div className="AccordionCont">
        <img
          src={loader}
          alt="loader"
          className={`loader ${loading ? "active animate__animated animate__fadeIn" : ""}`}
        />
        {userData && userData.userData && (
          <>
            <AccordionItem
              eventKey="0"
              icon={pInfo}
              title="פרטים אישיים"
              component={PersonalInformation}
              userData={userData.userData}
              isMobile={isMobile}
            />
            <AccordionItem
              eventKey="1"
              icon={ic2}
              title="כתובת"
              component={Address}
              userData={userData.userData}
              isMobile={isMobile}
            />
            <AccordionItem
              eventKey="2"
              icon={ic3}
              title="פרטים לשעת חירום"
              component={Emergency}
              userData={userData.userData}
              isMobile={isMobile}
            />
            <AccordionItem
              eventKey="3"
              icon={ic4}
              title="לימודים והשכלה"
              component={Studies}
              userData={userData.userData}
              isMobile={isMobile}
              style={{ position: 'relative', zIndex: '10' }}
            />
            <AccordionItem
              eventKey="4"
              icon={ic5}
              title="שירות לאומי"
              component={Sherut}
              userData={userData.userData}
              isMobile={isMobile}
            />
          </>
        )}
      </div>
    </div>
  );
};

const AccordionItem = React.memo(({ eventKey, icon, title, component: Component, userData, isMobile, style }) => (
  <Accordion className={`accordions acc${parseInt(eventKey) + 1}`} style={style}>
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <img src={icon} alt={title} />
        <span>{title}</span>
      </Accordion.Header>
      <Accordion.Body>
        <Component isMobile={isMobile} userData={userData} />
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
));

export default React.memo(UserConsoleDataIndex);
