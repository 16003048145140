import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";

const MultiSelectReact = ({ options, disabled, currentState, clearMulti, handleChangeThum }) => {
  const [selected, setSelected] = useState([]);
  const [clearMultiState, setClearMultiState] = useState(clearMulti);

  useEffect(() => {
    console.log("MultiSelectReact - Options:", options);
    console.log("MultiSelectReact - Current State Thum:", currentState?.Thum);
  }, [options, currentState?.Thum]);

  useEffect(() => {
    if (clearMultiState !== clearMulti) {
      console.log("MultiSelectReact - Clearing selection");
      setSelected([]);
      setClearMultiState(clearMulti);
    }
  }, [clearMulti, clearMultiState]);

  useEffect(() => {
    if (currentState?.Thum) {
      const allParams = Array.isArray(currentState.Thum) 
        ? currentState.Thum.map(element => {
            const matchingOption = options.find(opt => opt.value === element);
            return {
              label: matchingOption?.label || element,
              value: element
            };
          })
        : [];
      console.log("MultiSelectReact - Setting selected items:", allParams);
      setSelected(allParams);
    } else {
      setSelected([]);
    }
  }, [currentState?.Thum, options]);

  const handleSelected = (params) => {
    console.log("MultiSelectReact - handleSelected called with:", params);
    setSelected(params);
    const values = params.map(element => element.value);
    console.log("MultiSelectReact - Calling handleChangeThum with values:", values);
    handleChangeThum(values);
  };

  const text = {
    allItemsAreSelected: "נבחר הכל",
    clearSearch: "Clear Search", 
    noOptions: "אין אופציות",
    search: "חיפוש",
    selectAll: "בחרו הכל",
    selectAllFiltered: "Select All (Filtered)",
    selectSomeItems: "בחרו תחום"
  };

  return (
    <MultiSelect
      className={disabled ? "multiSelect disabled" : "multiSelect"}
      disabled={disabled}
      options={options}
      value={selected}
      onChange={handleSelected}
      hasSelectAll={false}
      disableSearch={true}
      overrideStrings={text}
    />
  );
};

export default MultiSelectReact;
