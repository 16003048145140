import React, { useState, useEffect } from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import getDataFromApi from "../../../Components/-Helpers-/api/getDataFromApi";
import ShowFilters from "./ShowFilters";

const SearchUp = ({ currentState, updateSearch }) => {
  const history = useHistory();
  const [state, setState] = useState({
    searchDataResp: null,
    loading: false,
    freeSearch: "",
    rakaz: "",
    city: "",
    sayeretId: currentState.sayeretId || "",
    updateFilters: false
  });

  const handleChange = (event) => {
    setState(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  // this is not to be removed
  const triggerApiToPopulateSayarot = async () => {
    const api = 'https://sherut-leumi.wdev.co.il/api/sayarot/getSayarot'
    fetch(api)
    .then(res => res.json())
    .then(data => {
      console.log("data:", data);
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
  }
  useEffect(() => {
    triggerApiToPopulateSayarot();
  }, []);

  useEffect(() => {
    console.log("currentState:", currentState);
    
    if (!state.searchDataResp || currentState.sayeretId) {
      sendtoAdmin(
        "getSayarotSearchParams",
        "sayarot",
        {},
        "searchDataResp",
        "sayarot"
      );
    }

    if (currentState.sayeretId) {
      setState(prev => ({
        ...prev,
        sayeretId: currentState.sayeretId,
        updateFilters: Date.now()
      }));
    }
  }, [currentState.sayeretId]);

  const sendtoAdmin = async (url, controller, objectToSend, setStateName = "data", auth = "all") => {
    console.log("OBJECTTOSEND", objectToSend);
    setState(prev => ({ ...prev, loading: true }));

    try {
      const getData = await getDataFromApi(url, objectToSend, controller, auth);
      
      setState(prev => ({ 
        ...prev, 
        [setStateName]: getData, 
        loading: false,
        ...(url === "getSayarotSearchParams" && getData?.rakazot && currentState.rakaz ? {
          rakaz: currentState.rakaz,
          sayeretId: currentState.sayeretId,
          updateFilters: Date.now()
        } : {})
      }));
    } catch (error) {
      console.error("Error in sendtoAdmin:", error);
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const sendForm = () => {
    const searchState = {
      freeSearch: state.freeSearch || "",
      rakaz: state.rakaz || "",
      city: state.city || "",
      sayeretId: state.sayeretId || ""
    };
    
    // First update the local state
    setState(prev => ({ ...prev, updateFilters: Date.now() }));
    
    // Then immediately send the search request
    sendtoAdmin(
      "getSayarotSearchParams",
      "sayarot",
      searchState,
      "searchDataResp",
      "sayarot"
    );
    
    // Update parent component
    updateSearch(searchState);
  };

  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    setState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const searchFilters = async (param) => {
    setState(prev => ({ 
      ...prev, 
      [param]: "",
      loading: true,
      updateFilters: Date.now()
    }));

    // Call API to reset results
    await sendtoAdmin(
      "getSayarotSearchParams",
      "sayarot",
      {}, 
      "searchDataResp",
      "sayarot"
    );
    
    sendForm();
  };

  const getRandomImage = () => {
    const randomNum = Math.floor(Math.random() * 20) + 1; // Random number between 1-20
    return `/randomPic/${randomNum}_converted.png`;
  };

  const resetSearch = async () => {
    const emptyState = {
      freeSearch: "",
      rakaz: "",
      city: "",
      sayeretId: ""
    };

    setState(prev => ({
      ...prev,
      ...emptyState,
      loading: true,
      updateFilters: Date.now()
    }));

    await sendtoAdmin(
      "getSayarotSearchParams",
      "sayarot",
      {}, 
      "searchDataResp",
      "sayarot"
    );

    // Clear URL parameters
    history.push(window.location.pathname);

    updateSearch(emptyState);
  };

  const hasActiveFilters = state.freeSearch || state.city || state.rakaz || state.sayeretId;

  return (
    <div className="SearchAndResults">
      <div className="SearchUpCont GreyPanel">
        {state.searchDataResp && (
          <div>
            <div className="line">
              <Form.Group className="inputsWithLabel searchIco">
                <Form.Label>חיפוש חופשי</Form.Label>
                <FormControl
                  placeholder="שם הסיירת, תיאור"
                  className="inputsWithLabel"
                  onChange={handleChange}
                  name="freeSearch"
                  value={state.freeSearch}
                  type="text"
                />
              </Form.Group>
            </div>

            <div className="line seccondLine">
              <Form.Group className="inputsWithLabel selectIco2 rakazIco iconAfter2">
                <Form.Label>לפי רכזת</Form.Label>
                <FormControl
                  as="select"
                  className="inputsWithLabel selectIcoBG"
                  onChange={handleChangeSelect}
                  name="rakaz"
                  value={state.rakaz}
                >
                  <option value="">בחרו רכז/ת</option>
                  <option disabled>----</option>
                  {state.searchDataResp.rakazot?.map((item) => (
                    <option value={item.Rak_Key} key={item.Rak_Key}>
                      {item.Rak_Value}
                    </option>
                  ))}
                </FormControl>
              </Form.Group>

              <Form.Group className="inputsWithLabel selectIco2 placeIco iconAfter2">
                <Form.Label>לפי ישוב</Form.Label>
                <FormControl
                  as="select"
                  className="inputsWithLabel selectIcoBG"
                  onChange={handleChangeSelect}
                  name="city"
                  value={state.city}
                >
                  <option value="">בחרו ישוב</option>
                  <option disabled>----</option>
                  {state.searchDataResp.cities?.map((item) => (
                    <option value={item.City_Key} key={item.City_Key}>
                      {item.City_Value}
                    </option>
                  ))}
                </FormControl>
              </Form.Group>

              <Button
                disabled={!hasActiveFilters}
                className="sendBlueBtn withDisabled"
                onClick={sendForm}
                size="lg"
                variant={hasActiveFilters ? "success animate__animated animate__bounceIn" : "disabled"}
              >
                חיפוש
              </Button>

              {hasActiveFilters && (
                <Button
                  className="resetBtn my-2 mx-2"
                  onClick={resetSearch}
                  size="lg"
                  variant="outline-secondary"
                >
                  איפוס חיפוש ♻️
                </Button>
              )}
            </div>
          </div>
        )}
        {state.loading && (
          <div className="text-center mt-3">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">טוען...</span>
            </div>
          </div>
        )}
      </div>

      {hasActiveFilters && state.updateFilters && (
        <ShowFilters
          updateFilters={state.updateFilters}
          searchData={state.searchDataResp}
          currentState={state}
          callBack={searchFilters}
        />
      )}
    </div>
  );
};

export default SearchUp;
