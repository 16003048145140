import React, { useState, useEffect, useCallback } from "react";
import loader from "../../../img/preLoader.gif";
import getDataFromApi from "../../../Components/-Helpers-/api/getDataFromApi";
import SearchResultRow from "./SearchResultRow";

export default function MySayarot({ changeMenuright }) {
  const [sayarotResp, setSayarotResp] = useState(null);
  const [loading, setLoading] = useState(true);  // Initialize as true since we load on mount
  const [error, setError] = useState(null);
  
  const getMySayarot = useCallback(async () => {
    if (loading) return; // Prevent multiple simultaneous requests
    setLoading(true);
    setError(null);
    try {
      const user = JSON.parse(localStorage.getItem("userData"));
      if (!user?.IDNO || !user?.SessionKey) {
        throw new Error("Missing user data");
      }

      const sendObj = {
        IDNO: user.IDNO,
        SessionKey: user.SessionKey,
        isRotemDev: true,
      };

      const getData = await getDataFromApi(
        "api/v2/Volunteer/syr/my",
        sendObj,
        "",
        "sayarot",
        "https://vu-apiws-autosc.azurewebsites.net/"
      );

      if (getData?.Sayarot) {
        getData.Sayarot = getData.Sayarot.map(sayara => ({
          ...sayara,
          ID: sayara.SayertId || sayara.ID
        }));

        // Update user's localStorage with current Sayarot
        const user = JSON.parse(localStorage.getItem("userData"));
        if (user) {
          const sayarotIds = getData.Sayarot.map(sayara => sayara.ID);
          localStorage.setItem("userData", JSON.stringify({
            ...user,
            Sayarot: sayarotIds
          }));
        }
      }

      setSayarotResp(getData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    getMySayarot();
  }, []); 

  const handlechange = useCallback((changes) => {
    changeMenuright(changes);
    
    if (changes.changeSayeret) {
      getMySayarot();
    }
  }, [changeMenuright, getMySayarot]);

  if (loading) {
    return (
      <div className="SearchResults my-sayarot">
        <img
          src={loader}
          alt="loader"
          className="loader active animate__animated animate__fadeIn"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="SearchResults my-sayarot">
        <div className="error-message">
          <h2>שגיאה בטעינת הנתונים</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="SearchResults my-sayarot">
      {sayarotResp?.Sayarot?.length > 0 ? (
        <div className="sayarot-list">
          {sayarotResp.Sayarot.map((sayara) => (
            <SearchResultRow
              key={sayara.ID}
              item={sayara}
              changeMenuright={handlechange}
              isMyPage={true}
            />
          ))}
        </div>
      ) : (
        <h2 className="noResults">אין סיירות...</h2>
      )}
    </div>
  );
}
