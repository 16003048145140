import React from 'react'
import ContentWrapper from '../../Components/loginPages/ContentWrapper'

const MekomotSherutIndex = (props) => {
  if (props.isMobile) {
    return (
      <div className="body-container Mobile MekomotSherutIndex">
        <ContentWrapper {...props} />
      </div>
    )
  }

  return (
    <div className="body-container Desktop">
      <div className="content-wrapper twoColumns">
        <div className="mainContent">
          <ContentWrapper {...props} />
        </div>
      </div>
    </div>
  )
}

export default MekomotSherutIndex
