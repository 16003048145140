import React, { useState, useEffect, Fragment } from "react";
import { RestUrls } from "../../Components/-Helpers-/config";
import axios from "axios";
import SearchUp from "./search/SearchUp";
import SearchResults from "./search/SearchResults";
import SearchCategory from "./search/SearchCategory";
import MySayarot from "./search/MySayarot";
import InfiniteScroll from "react-infinite-scroller";
import { animateScroll as scroll } from "react-scroll";
import goUp from "../../img/sherut-leumi/svg/goUp.svg";
import loader from "../../img/preLoader.gif";
import vPic from "../../img/sherut-leumi/svg/sherutPlaces/v.svg";
import { Form } from "react-bootstrap";
import { getAllUrlParams } from "./../../Components/-Helpers-/UrlParameters";
import TextDayShowSayarot from "./texts/TextDayShowSayarot";

const UserConsoleSearchIndex = ({ changeMenuright, siteInfo }) => {
  // Initialize state from URL parameters and localStorage
  const urlQuery = getAllUrlParams(window.location.href);
  console.log('URL Parameters:', {
    urlQuery,
    fullUrl: window.location.href,
    rakazidFromUrl: urlQuery.rakazid,
    rakazIdFromUrl: urlQuery.rakazId
  });

  const initialRakazId = urlQuery.rakazid || urlQuery.rakazId || localStorage.getItem("rakazid") || "";
  const initialSayeretId = urlQuery.sayeretid || urlQuery.sayeretId || localStorage.getItem("sayeretid") || "";

  console.log('Initial IDs:', {
    initialRakazId,
    initialSayeretId,
    fromLocalStorage: {
      rakazid: localStorage.getItem("rakazid"),
      sayeretid: localStorage.getItem("sayeretid")
    }
  });

  // Clear localStorage values after reading
  localStorage.setItem("rakazid", "");
  localStorage.setItem("sayeretid", "");

  // State declarations using hooks
  const [loading, setLoading] = useState(false);
  const [freeSearch, setFreeSearch] = useState("");
  const [rakaz, setRakaz] = useState(initialRakazId);
  const [sayeretId, setSayeretId] = useState(initialSayeretId);
  const [city, setCity] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [searchFilters, setSearchFilters] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [nextHref, setNextHref] = useState(0);
  const [pageName, setPageName] = useState("sayarot");
  const [showGreenOk, setShowGreenOk] = useState(false);
  const [noSayarotMessage, setNoSayarotMessage] = useState("");
  const itemsPerPage = 20;

  // Load initial data on component mount
  useEffect(() => {
    console.log('Component mounted, current state:', {
      rakaz,
      sayeretId,
      initialRakazId,
      initialSayeretId
    });
    if (initialRakazId) {
      setNoSayarotMessage("אין לרכזת סיירות");
    }
    // Instead of calling loadInitialData, we'll trigger loadItems directly
    loadItems();
  }, []);

  // Update search state
  const updateStateFromSearch = async (searchState) => {
    console.log('==================== FILTER UPDATE ====================');
    console.log('1. Received New Search State:', searchState);
    
    // Immediately update UI state to show we're processing the search
    setLoading(true);
    setItems([]); // Clear current results immediately
    setNoSayarotMessage("טוען תוצאות..."); // Show loading message in Hebrew
    
    try {
      const userJ = JSON.parse(localStorage.getItem("userData"));
      const sessionKey = userJ?.SessionKey;

      const API = "https://vu-apiws-autosc.azurewebsites.net/api/v2/Data/sayarot";
      const sendObj = {
        token: RestUrls.Code,
        page: 1,
        pageSize: itemsPerPage,
        filterParams: {
          SayertId: sayeretId || "",
          RakazId: searchState.rakaz || "",
          CityId: searchState.city || "",
          FreeText: searchState.freeSearch || "",
          OrderBy: searchState.orderBy || "",
        },
        SessionKey: sessionKey,
      };

      // Update filter state before API call
      setFreeSearch(searchState.freeSearch || "");
      setRakaz(searchState.rakaz || "");
      setCity(searchState.city || "");
      setOrderBy(searchState.orderBy || "");

      console.log('2. Sending Search Request:', {
        filters: sendObj.filterParams
      });

      const response = await axios.post(API, JSON.stringify(sendObj), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const data = response?.data;
      if (!data || !Array.isArray(data.Sayarot)) {
        setItems([]);
        setNoSayarotMessage(rakaz ? "אין לרכזת סיירות" : "אין תוצאות לחיפוש זה");
        setHasMoreItems(false);
        return;
      }

      const mappedItems = data.Sayarot.map(item => ({
        ...item,
        ID: item.ID?.toString(),
        name: item.Name || "לא צוין",
        city: item.City?.Value || "לא צוין",
        rakaz: item.Rak?.Value || "לא צוין",
        Image: item.Image || `${RestUrls.pagesPictures}/search/defaultImage.jpg`
      }));

      // Update results state
      setItems(mappedItems);
      setNextHref(data.CurrentPage);
      setHasMoreItems(data.CurrentPage < data.TotalPages);
      
      // Set appropriate message based on results
      if (mappedItems.length === 0) {
        setNoSayarotMessage("אין תוצאות לחיפוש זה");
      } else {
        const totalResults = data.TotalItems || mappedItems.length;
        setNoSayarotMessage(
          `נמצאו ${totalResults} תוצאות${data.TotalPages > 1 ? ' (גלול למטה לתוצאות נוספות)' : ''}`
        );
      }

      console.log('3. Search Complete:', {
        resultsCount: mappedItems.length,
        totalItems: data.TotalItems,
        hasMore: data.CurrentPage < data.TotalPages
      });
      
    } catch (err) {
      console.error('Error in search:', err);
      setItems([]);
      setNoSayarotMessage("שגיאה בטעינת נתונים - אנא נסי שוב");
      setHasMoreItems(false);
    } finally {
      setLoading(false);
      console.log('==================== END FILTER UPDATE ====================\n');
    }
  };

  // Handle select change
  const handleChangeSelect = (event) => {
    console.log('=== handleChangeSelect START ===');
    console.log('New order by value:', event.target.value);
    
    const newOrderBy = event.target.value;
    setOrderBy(newOrderBy);
    
    console.log('Current state before reset:', { items: items.length, nextHref, hasMoreItems, loading });
    
    // Reset pagination and items
    setItems([]);
    setNextHref(0);
    setHasMoreItems(true);
    setNoSayarotMessage("");
    setLoading(false);

    console.log('State reset complete');
    console.log('=== handleChangeSelect END ===');

    // Load items with new order after a short delay
    console.log('Scheduling loadItems...');
    setTimeout(() => {
      console.log('Executing delayed loadItems');
      loadItems();
    }, 0);
  };

  // Load more items for infinite scroll
  const loadItems = async () => {
    if (loading) return;
    
    const debugState = {
      currentFilters: {
        freeSearch,
        rakaz,
        city,
        sayeretId,
        orderBy
      },
      paginationState: {
        nextHref,
        hasMoreItems,
        itemsPerPage
      },
      currentItems: items.length,
      loading
    };
    
    console.log('==================== LOADING MORE ITEMS ====================');
    console.log('1. Current Application State:', JSON.stringify(debugState, null, 2));
    
    setLoading(true);
    
    try {
      const userJ = JSON.parse(localStorage.getItem("userData"));
      const sessionKey = userJ?.SessionKey;

      const API = "https://vu-apiws-autosc.azurewebsites.net/api/v2/Data/sayarot";
      const sendObj = {
        token: RestUrls.Code,
        page: nextHref === 0 ? 1 : nextHref + 1,
        pageSize: itemsPerPage,
        filterParams: {
          SayertId: sayeretId || "",
          RakazId: rakaz || "",
          CityId: city || "",
          FreeText: freeSearch || "",
          OrderBy: orderBy || "",
        },
        SessionKey: sessionKey,
      };

      const response = await axios.post(API, JSON.stringify(sendObj), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const data = response?.data;
      if (!data || !Array.isArray(data.Sayarot)) {
        setHasMoreItems(false);
        return;
      }

      const mappedItems = data.Sayarot.map(item => ({
        ...item,
        ID: item.ID?.toString(),
        name: item.Name || "לא צוין",
        city: item.City?.Value || "לא צוין",
        rakaz: item.Rak?.Value || "לא צוין",
        Image: item.Image || `${RestUrls.pagesPictures}/search/defaultImage.jpg`
      }));

      if (nextHref === 0) {
        setItems(mappedItems);
      } else {
        setItems(prev => [...prev, ...mappedItems]);
      }

      setNextHref(data.CurrentPage);
      setHasMoreItems(data.CurrentPage < data.TotalPages);
      
      // Update the message to show progress
      const totalResults = data.TotalItems || items.length + mappedItems.length;
      const currentShowing = items.length + mappedItems.length;
      setNoSayarotMessage(
        `מציג ${currentShowing} מתוך ${totalResults} תוצאות${data.CurrentPage < data.TotalPages ? ' (גלול למטה לתוצאות נוספות)' : ''}`
      );
      
    } catch (err) {
      console.error('Error loading more items:', err);
      setHasMoreItems(false);
      // Don't clear existing items on error when loading more
    } finally {
      setLoading(false);
    }
  };

  // Load initial data from API
  const loadInitialData = async () => {
    console.log('Loading initial data with rakaz:', rakaz);
    if (!hasMoreItems || loading) {
      console.log('Skipping initial load:', { hasMoreItems, loading });
      return;
    }
    
    setLoading(true);
    try {
      const userJ = JSON.parse(localStorage.getItem("userData"));
      const sessionKey = userJ?.SessionKey;

      const API = "https://vu-apiws-autosc.azurewebsites.net/api/v2/Data/sayarot";
      const sendObj = {
        token: RestUrls.Code,
        page: nextHref,
        pageSize: itemsPerPage,
        filterParams: {
          SayertId: sayeretId,
          RakazId: rakaz,
          cityId: city,
          freeSearch,
          orderBy,
        },
        SessionKey: sessionKey,
      };

      console.log('Loading initial data with payload:', sendObj);

      const response = await axios.post(API, JSON.stringify(sendObj), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const data = response?.data;
      if (!data || !Array.isArray(data.Sayarot)) {
        console.log('Invalid initial data format received:', data);
        setHasMoreItems(false);
        if (rakaz) {
          setNoSayarotMessage("אין לרכזת סיירות");
        }
        return;
      }

      // Process and set the initial data
      const mappedItems = data.Sayarot.map(item => ({
        ...item,
        ID: item.ID?.toString(),
        name: item.Name || "לא צוין",
        city: item.City?.Value || "לא צוין",
        rakaz: item.Rak?.Value || "לא צוין",
        Image: item.Image || `${RestUrls.pagesPictures}/search/defaultImage.jpg`
      }));

      setItems(mappedItems);
      setNextHref(data.NextPage || 1);
      setHasMoreItems(data.TotalPages ? data.NextPage < data.TotalPages : mappedItems.length === itemsPerPage);

      console.log('Initial data loaded:', {
        itemsCount: mappedItems.length,
        nextPage: data.NextPage,
        totalPages: data.TotalPages
      });
    } catch (err) {
      console.error('Error loading initial data:', err);
      alert("שגיאה בטעינת נתונים");
      setHasMoreItems(false);
    } finally {
      setLoading(false);
    }
  };

  // Handle menu change and show sign
  const changeMenuRightAndShowSign = (id) => {
    changeMenuright(id);

    if (!id.isActive) {
      setShowGreenOk(id.name);
      setTimeout(() => setShowGreenOk(false), 4000);
    }
  };

  const isMobile = siteInfo?.isMobile || false;
  const topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=2";
  const m_topPic = RestUrls.pagesPictures + "/register/m_registerTop.jpg?v=5";

  const loadingElement = (
    <div className="loading-container">
      <img
        src={loader}
        key={1231}
        alt="טוען נתונים..."
        className="loaderInfiniteScroll animate__animated animate__fadeIn"
      />
      <div className="loading-text">טוען נתונים...</div>
    </div>
  );

  return (
    <>
      <div className="SearchSection">
        {showGreenOk && (
          <div className="messaggePopUp animate__animated animate__fadeInDown">
            <div className="messaggeCont">
              <img src={vPic} alt="תודה שנרשמת לסיירת" />
              <span>תודה שנרשמת לסיירת {showGreenOk}</span>
            </div>
          </div>
        )}

        <img
          className="goUpPic animate__animated animate__bounceIn"
          src={goUp}
          alt="לעלות"
          onClick={() => scroll.scrollToTop({ duration: 0 })}
        />

        {isMobile ? (
          <a href="https://sherut-leumi.co.il/" target="_self" rel="noopener noreferrer">
            <img className="topPic full_width" src={m_topPic} alt="top" />
          </a>
        ) : (
          <img className="topPic full_width" src={topPic} alt="top" />
        )}

        <div className="SearchContainer">
          <h1>הסיירות של האגודה להתנדבות – בחרי את הסיירת שלך</h1>
          <div className="SearchCategoty">
            <SearchCategory
              currentPage={pageName}
              changePage={(name) => {
                console.log('Changing page to:', name);
                setPageName(name);
                setFreeSearch("");
                setRakaz("");
                setCity("");
                setItems([]);
                setHasMoreItems(true);
                setNextHref(0);
                setNoSayarotMessage("");
              }}
            />
          </div>

          {pageName === "mySayarot" ? (
            <div className="pageMySayarot">
              <MySayarot changeMenuright={changeMenuright} />
            </div>
          ) : (
            <div className="pageSayarot">
              <div className="SearchUp">
                <SearchUp
                  currentState={{ freeSearch, rakaz, city, orderBy }}
                  updateSearch={(searchState) => {
                    console.log('Updating search with:', searchState);
                    updateStateFromSearch(searchState);
                  }}
                />
              </div>

              <div className="bannerCall">
                <Fragment>
                  <TextDayShowSayarot isMobile={isMobile} step="step2" />
                </Fragment>
              </div>

              <div className="orderBy">
                <Form.Control
                  as="select"
                  className="customSelectInput"
                  name="orderBy"
                  onChange={handleChangeSelect}
                  value={orderBy}
                >
                  <option value="">מיון לפי</option>
                  <option value="מקומות שנותרו">מקומות שנותרו</option>
                  <option value="תאריך">תאריך</option>
                  <option value="מיקום">מיקום</option>
                </Form.Control>
              </div>

              <InfiniteScroll
                pageStart={0}
                loadMore={loadItems}
                hasMore={hasMoreItems}
                loader={loadingElement}
                threshold={100}
              >
                <div className="SearchResults">
                  {loading && items.length === 0 ? loadingElement : 
                   items.length > 0 ? (
                    <SearchResults
                      activeSayeretId={sayeretId}
                      changeMenuright={changeMenuRightAndShowSign}
                      sayarotData={items}
                    />
                  ) : (
                    <h2 className="noResults">{noSayarotMessage || "אין תוצאות..."}</h2>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserConsoleSearchIndex;
