import React, { useMemo } from "react";
import dayjs from "dayjs";
import date from "./../../../img/sherut-leumi/svg/search/resultsIcons/date.svg";
import rakaz from "./../../../img/sherut-leumi/svg/search/resultsIcons/rakaz.svg";
import mekomot from "./../../../img/sherut-leumi/svg/search/resultsIcons/mekomot.svg";
import homeOnly from "./../../../img/sherut-leumi/svg/search/resultsIcons/homeOnly.svg";

const formatDate = (dateStr) => {
  if (!dateStr || dateStr === "---") return "---";
  return dayjs(dateStr, "YYYYMMDD").format("DD/MM/YYYY");
};

const ShowDataInRow = ({ item }) => {
  const formattedDates = useMemo(() => ({
    syrDay: item?.SyrDay ? formatDate(item.SyrDay) : "---",
    regStart: item?.RegStart ? formatDate(item.RegStart) : "---"
  }), [item?.SyrDay, item?.RegStart]);

  const remainingSpots = useMemo(() => {
    // Special cases
    if (!item?.LimMtn || item.LimMtn === "901" || item.LimMtn === "903") return null;
    
    // Parse limit, ensure it's a valid number
    const limit = /^\d+$/.test(item.LimMtn) ? parseInt(item.LimMtn) : 0;
    
    // Parse current state, ensure it's a valid number
    const current = item?.Mtnstate && /^\d+$/.test(item.Mtnstate) ? parseInt(item.Mtnstate) : 0;
    
    const remaining = limit - current;
    return isNaN(remaining) ? 0 : remaining;
  }, [item?.LimMtn, item?.Mtnstate]);

  if (!item) return null;

  return (
    <div className="ShowDataInRow shadow-sm rounded-lg bg-white p-4">
      <header className="border-b pb-3 mb-4">
        <h2 className="text-xl font-bold text-gray-800">
          {item.Name || "טרם נקבע"}
        </h2>
      </header>

      <ul className="iconsLine grid gap-3">
        {item.SyrDay && item.SyrDay !== "---" && (
          <li className="flex items-center gap-2 text-gray-700">
            <div className="w-6 h-6 flex-shrink-0">
              <img src={date} alt="" className="w-full h-full object-contain" />
            </div>
            <span className="font-medium">תאריך סיירת:</span>
            <span>{formattedDates.syrDay}</span>
          </li>
        )}

        {item.Rak?.Value && (
          <li className="flex items-center gap-2 text-gray-700">
            <div className="w-6 h-6 flex-shrink-0">
              <img src={rakaz} alt="" className="w-full h-full object-contain" />
            </div>
            <span className="font-medium">רכז/ת:</span>
            <span>{item.Rak.Value}</span>
          </li>
        )}

        {item.Rak?.Phone && (
          <li className="flex items-center gap-2 text-gray-700">
            <div className="w-6 h-6 flex-shrink-0">
              <img src={rakaz} alt="" className="w-full h-full object-contain" />
            </div>
            <span className="font-medium">טלפון רכז/ת:</span>
            <span dir="ltr">{item.Rak.Phone}</span>
          </li>
        )}

        {item.MeetingPlace && (
          <li className="flex items-center gap-2 text-gray-700">
            <span className="font-medium">מקום מפגש:</span>
            <span>{item.MeetingPlace}</span>
          </li>
        )}

        {item.City?.Value && (
          <li className="flex items-center gap-2 text-gray-700">
            <span className="font-medium">עיר:</span>
            <span>{item.City.Value}</span>
          </li>
        )}

        {item.Institutions && (
          <li className="flex items-center gap-2 text-gray-700">
            <span className="font-medium">מוסדות:</span>
            <span>{item.Institutions}</span>
          </li>
        )}

        {remainingSpots !== null && (
          <li className="flex items-center gap-2 text-gray-700">
            <div className="w-6 h-6 flex-shrink-0">
              <img src={mekomot} alt="" className="w-full h-full object-contain" />
            </div>
            <span className="font-medium">מקומות שנותרו:</span>
            <span>{remainingSpots}</span>
          </li>
        )}

        {item.LimMtn === "901" && (
          <li className="flex items-center gap-2 text-gray-700">
            <div className="w-6 h-6 flex-shrink-0">
              <img src={mekomot} alt="" className="w-full h-full object-contain" />
            </div>
            <span className="font-medium">רשימת המתנה</span>
          </li>
        )}

        {item.LimMtn === "903" && (
          <li className="flex items-center gap-2 text-gray-700">
            <div className="w-6 h-6 flex-shrink-0">
              <img src={mekomot} alt="" className="w-full h-full object-contain" />
            </div>
            <span className="font-medium">ללא הגבלה</span>
          </li>
        )}

        {item.HomeOnly === "1" && (
          <li className="flex items-center gap-2 text-gray-700">
            <div className="w-6 h-6 flex-shrink-0">
              <img src={homeOnly} alt="" className="w-full h-full object-contain" />
            </div>
            <span className="font-medium">ללא דירת שרות</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default React.memo(ShowDataInRow);
