import React, { useState, useEffect, useCallback, useRef } from "react";
import { getAllUrlParams } from "../../Components/-Helpers-/UrlParameters";
import { RestUrls } from "../../Components/-Helpers-/config";
import styled from 'styled-components';

import SearchUpPlaces from "./includes/SearchUpPlaces";
import SearchResultsPlaces from "./includes/SearchResultsPlaces";
import InfiniteScroll from "react-infinite-scroller";
import loader from "../../img/preLoader.gif";

import Fab from "@mui/material/Fab";
import SearchIcon from "@mui/icons-material/Search";

import { animateScroll as scroll } from "react-scroll";
import axios from "axios";

import FavoriteButton from "./includes/FavoriteButton";
import ShowInfoPopUp from "./ShowInfoPopUp";

// Styled Components
const StyledContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  h1 {
    text-align: center;
    color: #2c3e50;
    margin: 30px 0;
    font-size: 2.5em;
  }
`;

const TopImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;

  a {
    background: #3498db;
    color: white;
    padding: 12px 24px;
    border-radius: 25px;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      background: #2980b9;
      transform: translateY(-2px);
    }
  }
`;

const SearchSection = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 30px;
`;

const ResultsContainer = styled.div`
  min-height: 200px;
  
  .noResults {
    text-align: center;
    color: #7f8c8d;
    margin: 40px 0;
  }
`;

const LoaderImage = styled.img`
  display: block;
  margin: 20px auto;
`;

const FloatingSearchButton = styled(Fab)`
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

// Validation helper functions
const validateStateValue = (value, defaultValue = "") => {
  if (value === "true" || value === true || value === undefined || value === null) {
    return defaultValue;
  }
  return value;
};

const validateArrayValue = (value, defaultValue = []) => {
  if (!Array.isArray(value)) {
    return defaultValue;
  }
  return value.filter(item => item !== "true" && item !== true);
};

// Wrap SearchResultsPlaces with React.memo for performance optimization
const MemoizedSearchResultsPlaces = React.memo(SearchResultsPlaces);

// Add aggregation helper function at the top level
const aggregateByMosadAndYear = (items) => {
  const grouped = {};
  
  items.forEach(item => {
    // Build key from all required fields
    const samelMosed = item.SamelMosed || "";
    const mosadName = item.MOSADNA || "";
    const rakValue = item.Rak_Value || "";
    const tchom1 = item.Thum?.TchomMos_value || "";
    const year = item.YEAR || "";  // Keep year in the key to maintain separation by year

    const key = [
      samelMosed,
      mosadName,
      rakValue,
      tchom1,
      year
    ].join("___");
    
    if (!grouped[key]) {
      // Initialize with first item of the group
      grouped[key] = {
        ...item,
        contracts: [item.ContractNo],
        original_ids: [item.id],
        TEKENB: parseInt(item.TEKENB || "0", 10),
        TEKENH: parseInt(item.TEKENH || "0", 10),
        TEKENP: parseInt(item.TEKENP || "0", 10),
        // Initialize pictures array and individual picture fields
        pictures: [],
        PICTURE1: item.PICTURE1 || "",
        PICTURE2: item.PICTURE2 || "",
        PICTURE3: item.PICTURE3 || "",
        PICTURE4: item.PICTURE4 || ""
      };

      // Add pictures from first item if they exist
      if (item.pictures && Array.isArray(item.pictures)) {
        grouped[key].pictures = [...item.pictures];
      }
    } else {
      // Add values to existing group
      grouped[key].TEKENB += parseInt(item.TEKENB || "0", 10);
      grouped[key].TEKENH += parseInt(item.TEKENH || "0", 10);
      grouped[key].TEKENP += parseInt(item.TEKENP || "0", 10);
      
      // Store all contract numbers and IDs
      grouped[key].contracts.push(item.ContractNo);
      grouped[key].original_ids.push(item.id);
      
      // If there are notes, concatenate them
      if (item.MAS_NOTES) {
        grouped[key].MAS_NOTES = grouped[key].MAS_NOTES 
          ? `${grouped[key].MAS_NOTES}\n${item.MAS_NOTES}`
          : item.MAS_NOTES;
      }
      
      // Update pictures if this item has newer timestamp
      if (item.updated_at > grouped[key].updated_at) {
        grouped[key].updated_at = item.updated_at;
        // Update individual picture fields from the most recent item
        grouped[key].PICTURE1 = item.PICTURE1 || grouped[key].PICTURE1;
        grouped[key].PICTURE2 = item.PICTURE2 || grouped[key].PICTURE2;
        grouped[key].PICTURE3 = item.PICTURE3 || grouped[key].PICTURE3;
        grouped[key].PICTURE4 = item.PICTURE4 || grouped[key].PICTURE4;
      }

      // Add any new pictures from the pictures array
      if (item.pictures && Array.isArray(item.pictures)) {
        grouped[key].pictures = [
          ...grouped[key].pictures,
          ...item.pictures.filter(pic => !grouped[key].pictures.includes(pic))
        ];
      }

      // Set grain to "1" if any contract has grain "1"
      if (item.grain === "1") {
        grouped[key].grain = "1";
      }
    }
  });
  
  // Convert numeric values back to strings and return
  return Object.values(grouped).map(item => ({
    ...item,
    TEKENB: String(item.TEKENB),
    TEKENH: String(item.TEKENH),
    TEKENP: String(item.TEKENP)
  }));
};

// Replace the old deduplication function with the new aggregation
const deduplicateByMosadnaAndYear = aggregateByMosadAndYear;

const SherutPlacesIndex = ({ siteInfo }) => {
  const urlParams = getAllUrlParams(window.location.search);
  const urlQuery = siteInfo.search ? getAllUrlParams(siteInfo.search) : false;

  console.log("[DEBUG] Initial URL Parameters:", {
    windowLocationSearch: window.location.search,
    urlParams,
    urlQuery,
    maslolFromUrl: urlParams?.maslol ? decodeURI(urlParams.maslol) : "",
    maslolFromSiteInfo: urlQuery?.maslol ? decodeURI(urlQuery.maslol) : ""
  });

  const favoritesPlacesRef = useRef([]);
  const uniqueIdsRef = useRef(new Set());

  const [state, setState] = useState({
    loading: false,
    freeSearch: validateStateValue(urlParams?.freesearch ? decodeURI(urlParams.freesearch) : ""),
    Maslol: validateStateValue(urlParams?.maslol ? decodeURI(urlParams.maslol) : ""),
    YEAR: validateStateValue(urlParams?.year ? decodeURI(urlParams.year) : 'תשפ"��'),
    Thum: validateArrayValue(
      urlParams?.thum && urlParams?.thum !== "null"
        ? decodeURI(urlParams.thum).split(",")
        : []
    ),
    rakaz: "",
    City: validateStateValue(urlParams?.city),
    ZoneName: validateStateValue(urlParams?.zonename),
    orderBy: "",
    searchFilters: false,
    items: [],
    hasMoreItems: true,
    nextHref: 0,
    sayarIdQuery: validateStateValue(urlQuery && urlQuery.id ? urlQuery.id : ""),
    pageName: "sayarot",
    allmekSherut: "0",
    favoritesPlaces: validateArrayValue(
      localStorage.getItem("favoritesPlaces")
        ? localStorage.getItem("favoritesPlaces").split(",")
        : []
    ),
    showfavoritesPlaces: false,
    openCloseSearchMobile: false,
    searchOptions: {
      cities: [],
      zones: [],
      years: [],
      categories: [],
      tracks: [],
      currentYear: "",
    },
  });

  // Add effect to track Maslol changes
  useEffect(() => {
    console.log("[DEBUG] Maslol state changed:", {
      newValue: state.Maslol,
      stack: new Error().stack
    });
  }, [state.Maslol]);

  // Reset uniqueIdsRef when search parameters change
  useEffect(() => {
    uniqueIdsRef.current = new Set();
  }, [state.freeSearch, state.YEAR, state.City, state.ZoneName, state.Thum, state.Maslol, state.showfavoritesPlaces, state.orderBy]);

  useEffect(() => {
    favoritesPlacesRef.current = state.favoritesPlaces;
  }, [state.favoritesPlaces]);

  const fetchSearchParams = useCallback(async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      
      const response = await axios.post(
        `${RestUrls.baseApiUrl}mekSherut/getMekSherutSearchParams`,
        {
          auth: "mekSherut",
          token: RestUrls.Code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.data) {
        setState(prev => ({
          ...prev,
          searchOptions: {
            cities: response.data.cities || [],
            zones: response.data.zones || [],
            years: response.data.years || [],
            categories: response.data.categories || [],
            tracks: response.data.tracks || [],
            currentYear: response.data.currentYear || "",
          },
          loading: false,
        }));
      }
    } catch (error) {
      console.error("Error fetching search parameters:", error);
      setState(prev => ({ ...prev, loading: false }));
    }
  }, []);

  useEffect(() => {
    fetchSearchParams();
  }, [fetchSearchParams]);

  const updateStateFromSearch = useCallback((searchState) => {
    console.log("[DEBUG] updateStateFromSearch called with:", {
      searchState,
      currentMaslol: state.Maslol
    });
    setState(prevState => {
      const newState = {
        ...prevState,
        ...Object.keys(searchState).reduce((acc, key) => {
          if (key === 'Thum') {
            acc[key] = validateArrayValue(searchState[key]);
          } else {
            const newValue = validateStateValue(searchState[key]);
            if (key === 'Maslol') {
              console.log("[DEBUG] Validating Maslol:", {
                original: searchState[key],
                validated: newValue
              });
            }
            acc[key] = newValue;
          }
          return acc;
        }, {}),
        items: [],
        hasMoreItems: true,
        nextHref: 0,
      };
      console.log("[DEBUG] New state after update:", {
        oldMaslol: prevState.Maslol,
        newMaslol: newState.Maslol
      });
      return newState;
    });
  }, [state.Maslol]);

  const handleChangeSelect = useCallback((event) => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: validateStateValue(value),
      items: [],
      hasMoreItems: true,
      nextHref: 0,
    }));
  }, []);

  const showFavoritesOnly = useCallback((param) => {
    setState(prevState => ({
      ...prevState,
      showfavoritesPlaces: param,
      items: [],
      hasMoreItems: true,
      nextHref: 0,
    }));
  }, []);

  const loadItems = useCallback(() => {
    const API = `${RestUrls.baseApiUrl}MekSherut/getMekSherutNEW`;
    
    const sendObj = {
      auth: "mekSherut",
      token: RestUrls.Code,
      page: state.nextHref,
      freeSearch: validateStateValue(state.freeSearch),
      year: validateStateValue(state.YEAR),
      City_Value: validateStateValue(state.City),
      City_Zone: validateStateValue(state.ZoneName),
      Thum_Key: validateArrayValue(state.Thum).map(thum => {
        const parts = thum.split('|');
        return validateStateValue(parts[0]);
      }),
      Maslol: validateStateValue(state.Maslol),
      sayarIdQuery: validateStateValue(state.sayarIdQuery),
      favoritesPlaces: Array.isArray(favoritesPlacesRef.current) 
        ? validateStateValue(favoritesPlacesRef.current.join(","))
        : "",
      showfavoritesPlaces: state.showfavoritesPlaces,
      openCloseSearchMobile: false,
      orderBy: validateStateValue(state.orderBy),
    };

    console.log("[DEBUG] API Request Details:", {
      endpoint: API,
      payload: {
        ...sendObj,
        rawState: {
          freeSearch: state.freeSearch,
          year: state.YEAR,
          city: state.City,
          zoneName: state.ZoneName,
          thum: state.Thum,
          maslol: state.Maslol,
          page: state.nextHref,
          favorites: favoritesPlacesRef.current
        },
        validatedValues: {
          freeSearch: validateStateValue(state.freeSearch),
          year: validateStateValue(state.YEAR),
          city: validateStateValue(state.City),
          zoneName: validateStateValue(state.ZoneName),
          thum: validateArrayValue(state.Thum),
          maslol: validateStateValue(state.Maslol)
        }
      }
    });

    axios.post(API, JSON.stringify(sendObj), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((res) => {
      console.log("[DEBUG] Raw API Response:", res);
      const { data } = res;
      console.log("[DEBUG] Extracted data:", data);
      
      if (data && data.items && data.items.length > 0) {
        console.log("[DEBUG] Found items in response, count:", data.items.length);
        const newItems = [];
        
        // Process and deduplicate items
        const processedItems = data.items.map(item => {
          if (item.Thum) {
            return {
              ...item,
              Thum: {
                ...item.Thum,
                categories: [
                  {
                    key: item.Thum.TchomMos_key,
                    value: item.Thum.TchomMos_value
                  },
                  ...(item.Thum.Tchom2_key ? [{
                    key: item.Thum.Tchom2_key,
                    value: item.Thum.Tchom2_value
                  }] : [])
                ]
              }
            };
          }
          return item;
        });

        // Filter out duplicates based on uniqueIdsRef
        processedItems.forEach((item) => {
          if (!uniqueIdsRef.current.has(item.id)) {
            uniqueIdsRef.current.add(item.id);
            newItems.push(item);
          } else {
            console.log("[DEBUG] Skipping duplicate item:", item.id);
          }
        });

        console.log("[DEBUG] New unique items to add:", newItems.length);

        if (newItems.length > 0) {
          setState(prevState => {
            // Combine existing and new items, then deduplicate based on MOSADNA and YEAR
            const allItems = [...prevState.items, ...newItems];
            const deduplicatedItems = deduplicateByMosadnaAndYear(allItems);
            
            console.log("[DEBUG] Items after deduplication:", {
              beforeCount: allItems.length,
              afterCount: deduplicatedItems.length
            });

            const updatedState = {
              ...prevState,
              allmekSherut: data.allmekSherut,
              items: deduplicatedItems,
              hasMoreItems: true,
              nextHref: prevState.nextHref + 1,
            };
            console.log("[DEBUG] Updated state:", updatedState);
            return updatedState;
          });
        } else {
          console.log("[DEBUG] No new unique items to add");
          setState(prevState => ({ ...prevState, hasMoreItems: false }));
        }
      } else {
        console.log("[DEBUG] No items in response or empty response");
        setState(prevState => ({ ...prevState, hasMoreItems: false }));
      }
    })
    .catch((err) => {
      console.error("[DEBUG] API Error:", err);
      alert("שגיאה");
    });
  }, [
    state.nextHref,
    state.freeSearch,
    state.YEAR,
    state.City,
    state.ZoneName,
    state.Thum,
    state.Maslol,
    state.sayarIdQuery,
    state.showfavoritesPlaces,
    state.orderBy
  ]);

  const closeSearchMobile = useCallback(() => {
    setState(prevState => ({ ...prevState, openCloseSearchMobile: false }));
    scroll.scrollTo(600);
  }, []);

  const topPic = RestUrls.pagesPictures + "/sherutPlaces/topSherut.jpg?v=3";
  const m_topPic = RestUrls.pagesPictures + "/sherutPlaces/topSherut.jpg?v=3";
  const isMobile = siteInfo.isMobile || false;
  const showFavoritesBtn = state.favoritesPlaces.length > 0;

  return (
    <StyledContainer className="SherutPlacesIndex">
      <TopImage
        className="topPic full_width"
        src={isMobile ? m_topPic : topPic}
        alt="top"
      />

      <ButtonsContainer className="btnsUp">
        <a href="/userConsole" target="_blank" rel="noopener noreferrer">
          <span>סיירות האגודה להתנדבות &raquo;</span>
        </a>
      </ButtonsContainer>

      <h1>מקומות השרות באגודה להתנדבות - לבחירתך!</h1>

      <div className="placesContainer">
        <div className="SearchCategoty">
          <FavoriteButton
            allResults={state.allmekSherut}
            is_disabled={!state.sayarIdQuery && showFavoritesBtn}
            is_all={state.showfavoritesPlaces}
            showFavoritesOnly={showFavoritesOnly}
          />
        </div>

        <ShowInfoPopUp />

        {!state.sayarIdQuery && !state.showfavoritesPlaces && (
          <SearchSection className="SearchUp">
            <SearchUpPlaces
              mobile={isMobile}
              closeSearch={closeSearchMobile}
              currentState={state}
              updateSearch={updateStateFromSearch}
            />
          </SearchSection>
        )}

        {isMobile && !state.sayarIdQuery && (
          <FloatingSearchButton
            className="mobileSearchBtnFloat searchMobileCont animate__animated animate__bounceIn"
            color="primary"
            aria-label="חיפוש"
            onClick={() => setState(prevState => ({ ...prevState, openCloseSearchMobile: !prevState.openCloseSearchMobile }))}
          >
            <SearchIcon />
          </FloatingSearchButton>
        )}

        {isMobile && !state.sayarIdQuery && state.openCloseSearchMobile && (
          <div className="searchMobileOpenClose animate__animated animate__fadeInDown animate__faster">
            <button
              className="openCloseSearchBtn xBtn"
              onClick={() => setState(prevState => ({ ...prevState, openCloseSearchMobile: false }))}
            >
              <span className="closeMe">X</span>
            </button>

            <div className="searchMobileCont">
              <SearchUpPlaces
                mobile={isMobile}
                closeSearch={closeSearchMobile}
                updateSearch={updateStateFromSearch}
              />
            </div>
          </div>
        )}

        {state.sayarIdQuery && (
          <div className="shareHeader">
            <a className="bounce" href="/sherutPlaces">
              &laquo; חזרה למקומות השרות
            </a>
          </div>
        )}

        <InfiniteScroll
          pageStart={0}
          loadMore={loadItems}
          hasMore={state.hasMoreItems}
          loader={<LoaderImage src={loader} key={1231} alt="loader" className="loaderInfiniteScroll animate__animated animate__fadeIn" />}
        >
          <ResultsContainer className="SearchResults" name="toSearchResults">
            {console.log("[DEBUG] Rendering SearchResults. Items:", state.items)}
            {state.items && state.items.length > 0 ? (
              <MemoizedSearchResultsPlaces
                showOpen={state.sayarIdQuery}
                items={state.items}
                favoritesPlaces={state.favoritesPlaces}
                chageFavorites={(chageFavorites) => {
                  console.log("[DEBUG] Changing favorites:", chageFavorites);
                  setState(prevState => ({
                    ...prevState,
                    favoritesPlaces: Array.isArray(chageFavorites) ? chageFavorites : []
                  }));
                }}
              />
            ) : (
              <h2 className="noResults">אין תוצאות...</h2>
            )}
          </ResultsContainer>
        </InfiniteScroll>
      </div>
    </StyledContainer>
  );
};

export default SherutPlacesIndex;