import React, { useState, useEffect } from "react";
import CustomFloatInput from "../../Components/-Helpers-/forms/CustomFloatInput";
import { RestUrls } from "../../Components/-Helpers-/config";
import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0
import loader from "../../img/preLoader.gif";
import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApiRotem";
import ModalDefaul from "../../Components/-Helpers-/ModalDefaul";

const ForgotPassword = ({ siteInfo }) => {
  const [IDNO, setIDNO] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseLogin, setResponseLogin] = useState(false);
  const [btnSendClass, setBtnSendClass] = useState("");
  const [checkInputs, setCheckInputs] = useState(false);
  const ReactNativeWebView = window.ReactNativeWebView;

  useEffect(() => {
    console.log(window.ReactNativeWebView, "window.ReactNativeWebView");
  }, []);

  const updateValue = (newValue) => {
    setIDNO(newValue.IDNO);
  };

  const sendForm = (sendRules) => {
    setLoading(false);

    if (sendRules) {
      setLoading(true);

      const sendObj = {
        IDNO: IDNO,
      };

      sendtoAdmin("remindPass", "Volunteer", sendObj, "responseLogin", "Volunteer");
    } else {
      setBtnSendClass("danger");
    }
  };

  const sendtoAdmin = async (url, controller, objectToSend, setStateName = "data", auth = "all") => {
    console.log("sending...");
    setLoading(true);

    try {
      const getData = await getDataFromApi(url, objectToSend, controller, auth);
      setLoading(false);

      if (getData.SMSResult === 'Success' && getData.Result === 'Success') {
        setResponseLogin({
          success: true,
          message: "סיסמתך נשלחה בהצלחה"
        });
        
        // Wait 2 seconds before redirecting
        setTimeout(() => {
          window.open("/login", "_self");
        }, 2000);
      } else if (getData.ErrorMessage) {
        setResponseLogin({
          error: getData.ErrorMessage
        });
      }
    } catch (error) {
      console.error("Error sending data:", error);
      setLoading(false);
      setResponseLogin({
        error: "אירעה שגיאה בשליחת הסיסמה"
      });
    }
  };

  const sendRules = IDNO;
  const isMobile = siteInfo.isMobile ? siteInfo.isMobile : false;
  let m_picTop = RestUrls.pagesPictures + "register/m_registerTop.jpg?v=3";

  return (
    <div className="firstRegister loginPage ForgotPassword animate__animated animate__fadeIn">
      <img
        src={loader}
        alt="loader"
        className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"}
      />

      {isMobile && <img className="topPic" src={m_picTop} alt="top" />}

      <header>
        <h1 className="boldTypeFamily">שכחת סיסמה?</h1>
        <p>הזן תעודת זהות והסיסמה תשלח אליך לשחזור</p>
      </header>

      <div className="inputs">
        <div className="line">
          <CustomFloatInput
            name="IDNO"
            updateValue={updateValue}
            value={IDNO}
            placeholder="תעודת זהות"
            cssClass=""
            validationRules={{ required: true, tz: true }}
            typeInput="text"
            checkInputs={checkInputs}
            checked={() => setCheckInputs(false)}
          />
        </div>

        <div style={{ textAlign: "center" }}>
          <br />
          <Button
            disabled={loading}
            className="sendBlueBtn"
            onClick={() => sendForm(sendRules)}
            size="lg"
            variant={sendRules ? "success animate__animated animate__bounceIn" : btnSendClass}
          >
            שלח סיסמה לנייד...
          </Button>
        </div>
      </div>

      {ReactNativeWebView === undefined && (
        <div className="text-center">
          <div className="singIn">
            <NavLink
              className="jumpPage"
              role="menuitem"
              onClick={() => scroll.scrollTo(0)}
              to={"/register"}
            >
              <span>
                עדין לא רשומים? <strong>לחצו להרשמה (נרשמים חדשים)</strong>
              </span>
            </NavLink>
          </div>
        </div>
      )}
      {responseLogin && responseLogin.error && (
        <ModalDefaul
          variant="error"
          params={{ title: "שגיאה", text: responseLogin.error }}
          callBack={() => setResponseLogin(false)}
        />
      )}
      {responseLogin && responseLogin.success && (
        <ModalDefaul
          variant="success"
          params={{ title: "הצלחה", text: responseLogin.message }}
          callBack={() => setResponseLogin(false)}
        />
      )}
    </div>
  );
};

export default ForgotPassword;
