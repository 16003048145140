/* eslint-disable no-useless-concat */
import axios from "axios";
import { toast } from "react-toastify";
import { RestUrls } from "../../Components/-Helpers-/config";
import { checkTextAreaReportContent } from "./clockFunctions";

function humanClock(hoursMinutes) {
    if(hoursMinutes) {
        const hours = hoursMinutes.substring(0, 2);
        const minutes = hoursMinutes.substring(2, 4);
        return hours + ':' + minutes;
    } else {
        return "00:00"
    }
}

function prettyDay(monthDay, monthNumber,currentYear) {
    const startDate = `${currentYear}-${monthNumber}-${monthDay}`;
    const monthYear = new Date(startDate.toString().replace(/-/g, '/')).toLocaleDateString('he', {
        weekday: 'long',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    if(monthYear === 'Invalid Date') {
        toast.error('שגיאה'); 
        return '---';
    }
    return monthYear;
}

function prettyDay2(monthDay, monthNumber,currentYear) {
    const startDate = `${currentYear}-${monthNumber}-${monthDay}`;
    const monthYear = new Date(startDate.toString().replace(/-/g, '/')).toLocaleDateString('he', {
        weekday: 'long',
        day: '2-digit',
    });

    if(monthYear === 'Invalid Date') {
        toast.error('שגיאה'); 
        return '---';
    }
    return monthYear;
}

export function dateFromString(yearMonthDay) {
    if(yearMonthDay) {
        const prettyDate = yearMonthDay.substring(6, 8) + '.' + yearMonthDay.substring(4, 6) + '.' + yearMonthDay.substring(0, 4);
        return prettyDate;    
    } else {
        return false
    }
}

export function getTypeRecord(id) {
    switch (id) {
        case '1':
            return 'נוכחות';
        case '2':
            return 'נוכחות בהכשרה';
        case '3':
            return 'מחוץ למקום השירות';
        case '4':
            return 'נוכחות ביום שבתון';
        default:
            return 'לא נבחר';
    }
}

export function getTypeRecordsList() {
    const data = [
        {id: '1', name: 'נוכחות'},
        {id: '2', name: 'נוכחות בהכשרה'},
        {id: '3', name: 'מחוץ למקום השירות'},
    ]
    return data;
}

export function getMonthData(url,sendObj,setLoading,setResponseData,setDaysMonthData) {
    setLoading(true);
    const json = JSON.stringify(sendObj);
    
    axios.post("https://vu-apiws-autosc.azurewebsites.net/api/" + url, json, {
        headers: {'Content-Type': 'application/json'},
    })
        .then( res => {
            setLoading(false);
            if(!res?.data?.Result || res?.data?.Result !== 'Success') {
                setResponseData(false);
                return toast.error('שגיאה בקריאת סטטוס');
            }

            if(!res?.data?.Shib[0]) {
                setResponseData(false);
                toast.error('לא נמצאו נתונים');
                return false;
            } else {
                populateMonthData(res.data.Shib[0],setResponseData,setDaysMonthData);
            }
        })
        .catch((error) => {
            setLoading(false);
            setResponseData({});
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
}

export function getAbsenceList(url,sendObj,setLoading,setResponseData) {
    setLoading(true);
    const json = JSON.stringify(sendObj);
    console.log("json getAbsence",json);
    axios.post( "https://vu-apiws-autosc.azurewebsites.net/api/" + url, json, {
        headers: {'Content-Type': 'application/json'},
    })
        .then( res => {
            setLoading(false);
            console.log("response of absence",res);
            if(!res?.data?.Items) {
                setResponseData(false);
                return toast.error('שגיאה');
            }
            setResponseData(res.data.Items);           
        })
        .catch((error) => {
            setLoading(false);
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
}

function populateMonthData(response,setResponseData,setDaysMonthData) {
    let isOpen = true;

    if(response.ReportConfirmationDate && response.ReportConfirmationDateByEmployee &&
        response.ReportConfirmationDateByManager ) {
            isOpen = false;
    }

    let items = [];

    if(response?.Dates && response.Dates.length > 0) {
        items = populateDayItems(+response.ReportID,response.Dates);
    }

    const monthData = {
        ReportID: +response.ReportID,
        isOpen: isOpen,
        Month: response.Month,
        Year: response.Year,
        LateReportQuota: +response.LateReportQuota,
        LateReportUsed: response.LateReportUsed,
        SumOfWorkingDays: +response.SumOfWorkingDays,
        SumOfWorkingHours: +response.SumOfWorkingHours,
        AmountOfUsedVacationDays: +response.AmountOfUsedVacationDays,
        ReportConfirmationDate: response.ReportConfirmationDate,
        ReportConfirmationDateByEmployee: response.ReportConfirmationDateByEmployee,
        ReportConfirmationDateByManager: response.ReportConfirmationDateByManager,
        ConfirmedSickDays: +response.ConfirmedSickDays,
        UnconfirmedSickDays: +response.UnconfirmedSickDays
    }

    setResponseData(monthData);
    setDaysMonthData(items)
}

function populateDayItems(ReportID,Dates) {
    let items = [];

    Dates.forEach(item => {
        let entryDays = [];

        if(item?.Times && item.Times.length > 0) {
            entryDays = populateEntryDays(ReportID, item.Times);
        }

        const isOk = checkIsOk(item?.Times);

        const newItem = {
            dayData : {
                        Reson: item?.Reson,
                        ResonLateMtn: item?.ResonLateMtn,
                        ResonLateRak: item?.ResonLateRak,
                        YM: item?.YM,
                        DOM: item?.DOM
                    },
            id: ReportID + '_' + item.DOM,
            title : prettyDay(item?.DOM, item.YM.substring(4, 6),item.YM.substring(0, 4)),
            title2 : prettyDay2(item?.DOM, item.YM.substring(4, 6),item.YM.substring(0, 4)),
            reportsCount: item?.Times ? item.Times.length : 0,
            isOk: isOk,
            isAbsence: item?.Reson ? item.Reson : false,
            items: entryDays
        }

        items.push(newItem);
    })

    return items.reverse();
}

function populateEntryDays(ReportID, entryDays) {
    let count = 1;
    let entryDaysNew = [];

    entryDays.forEach(item => {
        console.log('timeF - timeT - before',item.TimeF, item.TimeT);
        const timeF = item?.TimeF ? new Date(item.TimeF).toLocaleTimeString('en-GB', {hour: '2-digit', minute:'2-digit'}).replace(':', '') : false;
        const timeT = item?.TimeT ? new Date(item.TimeT).toLocaleTimeString('en-GB', {hour: '2-digit', minute:'2-digit'}).replace(':', '') : false;
        console.log('timeF - timeT - after',timeF, timeT);
        const newItem = {
            id: ReportID + '_' + count,
            in: timeF ? humanClock(timeF) : false,
            out: timeT ? humanClock(timeT) : false,
            api: item
        }

        entryDaysNew.push(newItem);
        count++;
    })

    return entryDaysNew;
}

function checkIsOk(entryDays) {
    return entryDays ? entryDays.every(item => item.TimeF && item.TimeT) : true;
}

export function checkData4Save(allChanges) {
    let okFlag = true;

    if( (allChanges?.Dates[0]?.Reson === 'חופשה') && allChanges?.Dates[0]?.Times && allChanges?.Dates[0]?.Times.length > 0 ) {
        toast.error('לא ניתן לבחור חופשה אם קיימים דיווחים');
        return false;
    }
    
    let sortedTimes = allChanges?.Dates[0]?.Times.sort((a, b) => new Date(a.TimeF) - new Date(b.TimeF));
    
    // Get current date and time for future validation
    const now = new Date();
    
    for(let i = 0; i < sortedTimes.length; i++) {
        let item = sortedTimes[i];
        
        if( ( item.Typ === '2' || item.Typ === '3' ) && !item.MoreInfo ) {
            okFlag = false;
            toast.error('יש להזין פירוט דיווח');
            return okFlag;
        }
        else if(!checkTextAreaReportContent(item.Typ,item.MoreInfo)) {
            okFlag = false;
            return false
        }
        else if( !item.Typ ) {
            okFlag = false;
            toast.error('יש לבחור סוג דיווח');
            return okFlag;
        }
        else if(!item.TimeF && !item.TimeT) {
            okFlag = false;
            toast.error('יש להזין שעות כניסה / יציאה');
            return okFlag;
        }
        else if(item.TimeF && item.TimeT) {
            const timeF = new Date(item.TimeF);
            const timeT = new Date(item.TimeT);

            if(timeT < timeF) {
                okFlag = false;
                const message = `שעת היציאה קטנה משעת הכניסה ${timeF.toLocaleTimeString('en-GB', {hour: '2-digit', minute:'2-digit'})} - ${timeT.toLocaleTimeString('en-GB', {hour: '2-digit', minute:'2-digit'})}`;
                toast.error(message ,{toastId: "customId"});
                return okFlag;
            }

            // Check if reporting for current day and exit time is in the future
            if (isSameDay(timeT, now) && timeT > now) {
                okFlag = false;
                toast.error('לא ניתן לדווח על שעת יציאה עתידית');
                return okFlag;
            }
        }
        // Check for overlapping times
        if(i < sortedTimes.length - 1) {
            let nextItem = sortedTimes[i+1];
            if(new Date(item.TimeT) > new Date(nextItem.TimeF)) {
                okFlag = false;
                toast.error('קיימת חפיפה בין זמני הדיווח');
                return okFlag;
            }
        }
    }

    return okFlag;
}

// Helper function to check if two dates are on the same day
function isSameDay(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
}

export function sendChangesToApi(url,sendObj,setLoading,allChanges,closeMe,reloadData) {
    console.log('url => ',url);

    const sendData = makeMyUpdateObj(sendObj,allChanges);
    console.log('sendData', sendData);

    if(!sendData) {toast.error('שגיאה');return false;}

    setLoading(true);
    
    const json = JSON.stringify(sendData);

    console.log('json',json);
    axios.post( "https://vu-apiws-autosc.azurewebsites.net/api/" + url, json, {
        headers: {'Content-Type': 'application/json'},
    })
        .then( res => {
            console.log("response of ",res);
            setLoading(false);
            console.log('response update',res?.data);

            if(res?.data?.Result === 'Error') {
                return toast.error('שגיאה');
            }

            if(res?.data?.ErrorMessage && res?.data?.ErrorMessage?.includes('OverQuota') ) {
                toast.error("מכסת הדיווחים באיחור נוצלה במלואה. כל או חלק מהדיווחים לא התקבלו. נא לפנות לרכזת להמשך טיפול");
                closeMe('bottom', false);
                reloadData();
            } 
            else if(res?.data?.Reason && res?.data?.Reason.includes('OverQuota') ) {
                console.log('yes');
                toast.error("מכסת הדיווחים באיחור נוצלה במלואה. כל או חלק מהדיווחים לא התקבלו. נא לפנות לרכזת להמשך טיפול");
                closeMe('bottom', false);
                reloadData();
            } 
            else if( res?.data?.Reason && res?.data?.Reason.includes('ימי ההיעדרות מסוג מחלה') ) {
                toast.error("ניצלת בעבר את מכסת ימי ההיעדרות מסוג מחלה סיבת ההיעדרות עודכנה לחופש");
                closeMe('bottom', false);
                reloadData();
            } else if( res?.data?.ErrorMessage && res?.data?.ErrorMessage.includes('OutOfOrder') ) {
                return toast.error('שגיאה, השעות חופפות'); 
            } else if( res?.data?.ErrorMessage && res?.data?.ErrorMessage.includes('ToMuchInOuts') ) {
                return toast.error('שגיאה, יותר מדי דיווחים ידניים');
            } else if( res?.data?.ErrorMessage &&  res?.data?.ErrorMessage.includes('Early') ) {
                return toast.error('שגיאה, דיווח מוקדם מהשעה המוגדרת'); 
            } else if(  res?.data?.Result === 'failed' ) {
                if(res?.data?.ErrorMessage) {
                    return toast.error(res?.data?.ErrorMessage); 
                } else {
                    return toast.error('שגיאה');
                }
            } else {
                toast.success('השינויים נשמרו בהצלחה');
                closeMe('bottom', false);
                reloadData();
            }
        })
        .catch((error) => {
            setLoading(false);
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
}

function makeMyUpdateObj(sendObj,allChanges) {
    console.log('Starting makeMyUpdateObj with:', { sendObj, allChanges });

    // Validate basic date requirements
    if(!allChanges?.Dates?.[0]?.YM || !allChanges?.Dates?.[0]?.DOM) {
        console.error('Missing date components:', allChanges?.Dates?.[0]);
        toast.error('שגיאה בנתוני תאריך');
        return false;
    }

    // Construct and validate the date
    const date = allChanges.Dates[0].YM + allChanges.Dates[0].DOM;
    const [year, month, day] = [
        date.substring(0, 4),
        date.substring(4, 6),
        date.substring(6, 8)
    ];
    
    const baseDate = `${year}-${month}-${day}`;
    console.log('Processing date:', { date, baseDate });
    
    let Times = [];
    
    // Validate and process times array
    if (Array.isArray(allChanges.Dates[0].Times)) {
        console.log('Initial times array:', allChanges.Dates[0].Times);
        
        // Process all times using reduce
        Times = allChanges.Dates[0].Times.reduce((acc, item) => {
            if (!item || !item.id) {
                console.log('Skipping invalid item:', item);
                return acc;
            }

            try {
                // Parse and validate input times
                const timeF = new Date(item.TimeF);
                const timeT = new Date(item.TimeT);
                
                if (isNaN(timeF.getTime()) || isNaN(timeT.getTime())) {
                    console.log('Invalid time values:', { timeF, timeT });
                    return acc;
                }

                // Create normalized times on the base date
                const normalizedTimeF = new Date(`${baseDate}T${timeF.getHours().toString().padStart(2, '0')}:${timeF.getMinutes().toString().padStart(2, '0')}:00`);
                const normalizedTimeT = new Date(`${baseDate}T${timeT.getHours().toString().padStart(2, '0')}:${timeT.getMinutes().toString().padStart(2, '0')}:00`);

                // Validate time range
                if (normalizedTimeT <= normalizedTimeF) {
                    console.log('Invalid time range:', { normalizedTimeF, normalizedTimeT });
                    return acc;
                }

                // Check for overlap with existing entries
                const hasOverlap = acc.some(existingTime => {
                    const existingStart = new Date(existingTime.TimeF);
                    const existingEnd = new Date(existingTime.TimeT);
                    return (normalizedTimeF < existingEnd && normalizedTimeT > existingStart);
                });

                if (hasOverlap) {
                    console.log('Overlapping time entry detected:', { normalizedTimeF, normalizedTimeT });
                    return acc;
                }

                // Create the time entry
                const timeEntry = {
                    TimeF: normalizedTimeF.toISOString(),
                    TimeT: normalizedTimeT.toISOString(),
                    Typ: item.Typ || "1",
                    MoreInfo: item.MoreInfo || ""
                };

                console.log('Adding valid time entry:', timeEntry);
                return [...acc, timeEntry];
            } catch (error) {
                console.error('Error processing time entry:', error);
                return acc;
            }
        }, []);

        // Sort final times array chronologically
        Times.sort((a, b) => new Date(a.TimeF) - new Date(b.TimeF));
        
        console.log('Final processed times:', {
            originalCount: allChanges.Dates[0].Times.length,
            processedCount: Times.length,
            times: Times
        });
    }

    const updateObj = {
        SessionKey: sendObj.SessionKey,
        idno: sendObj.IDNumber,
        Date: date,
        Times: Times,
        Reason: allChanges?.Dates[0]?.Reson || ""
    };

    // Validate the final object
    if(Times.length === 0 && !updateObj.Reason) {
        console.error('No valid times or reason provided');
        toast.error('נדרש להזין זמנים או סיבת היעדרות');
        return false;
    }

    console.log('Final update object:', updateObj);
    return updateObj;
}

// Helper function to validate time entries before sending to API
function validateTimeEntries(times) {
    if (!Array.isArray(times) || times.length === 0) return false;
    
    return times.every(time => {
        if (!time.TimeF || !time.TimeT) return false;
        
        const startTime = new Date(time.TimeF);
        const endTime = new Date(time.TimeT);
        
        // Basic validation checks
        if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) return false;
        if (endTime <= startTime) return false;
        
        return true;
    });
}

// Function to format times correctly for the API
function formatTimeForAPI(timeStr, baseDate) {
    try {
        const [hours, minutes] = timeStr.split(':').map(Number);
        const dateTime = new Date(baseDate);
        dateTime.setHours(hours, minutes, 0, 0);
        return dateTime.toISOString();
    } catch (error) {
        console.error('Error formatting time:', error);
        return null;
    }
}

export function getSummaryData(url,sendObj,setLoading,setResponseData) {
    setLoading(true);
    
    const json = JSON.stringify(sendObj);
    
    axios.post( "https://vu-apiws-autosc.azurewebsites.net" + "/api/v2/Volunteer/summary", json, {
        headers: {'Content-Type': 'application/json'},
    })
        .then( res => {
            setLoading(false);
            if(!res?.data?.Result || res?.data?.Result !== 'Success') {
                setResponseData(false);
                return toast.error('שגיאה');
            }
            
            if(res.data?.Years) {
                res.data.Years = res.data.Years.reverse();
            }

            setResponseData(res.data);
        })
        .catch((error) => {
            setLoading(false);
            setResponseData({});
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
}

export function confirmMonth(url,sendObj,setLoading,loadMonthData) {
    setLoading(true);
    
    const json = JSON.stringify(sendObj);

    axios.post( "https://vu-apiws-autosc.azurewebsites.net/api/"+ url, json, {
        headers: {'Content-Type': 'application/json'},
    })
        .then( res => {
            setLoading(false);
            console.log('responseConfirm',res);

            if(!res?.data?.Result || res?.data?.Result !== 'Success') {
                if(res?.data?.ErrorMessage === 'Incomplete report.') {
                    return toast.error('שגיאה, יש למלא את החוסרים');
                } else {
                    return toast.error('שגיאה');
                }
            } else {
                toast.success('החודש אושר בהצלחה');
                loadMonthData();
            }
        })
        .catch((error) => {
            setLoading(false);
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
}