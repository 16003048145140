import React, { useState } from 'react'
import info from './../../img/icons/info.svg'
import { Button, Modal } from 'react-bootstrap';

export default function ShowInfoPopUp() {

    const [modalShow, setModalShow] = useState(false);

  return (
    <div className='showInfoPopUp'>

        <button onClick={ ()=> setModalShow(true) }>
            <img src={info} alt='infoIcon' />
            <span>לחצ/י להסבר על חיפוש מקומות שירות</span>
        </button>

        <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite alertModal welcomeModal '  }
                animation={ false }
                onHide={() => setModalShow(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={ modalShow }
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
                <Modal.Body>

                    <Button className="closeBtn" onClick={() => setModalShow(false)} variant="secondary" >X</Button>

                    <div className="Message">
                        <h2>ברוכ/ה הבא/ה לאתר האגודה להתנדבות – שירות לאומי</h2>
                        <p className='p1'>הכר/י את כלי חיפוש מקומות השירות באגודה להתנדבות.
הנה הסבר קצר כיצד להשתמש בו כדי למצוא מקומות שירות שמעניינים אותך:
</p>
                        <ol>
                            <li>מסלול – במידה וקיבלת פטור מטעמי דת, יש לבחור "ממלכתי דתי" במידה וקיבלת פטור מכל סיבה אחרת, יש לבחור "של"מ".</li>
                            <li>שנה – במידה ואת/ה מעוניינ/ת להתחיל שירות לאומי בשנה הנוכחית – בחר/י תשפ"ה במידה ואת/ה מעוניינ/ת להתחיל שירות לאומי בשנה הבאה – בחר/י תשפ"ה.</li>
                            <li>תחום – יש לבחור אחד או יותר מהתחומים שמעניינים אותך.</li>
                            <li>אזור ויישוב – במידה ואת/ה מעוניינ/ת להתנדב במיקום ספציפי בארץ יש לבחור אזור / אזור + יישוב.</li>
                            <li>במידה ואת/ה מחפש/ת מקום שירות ספציפי ניתן להזין אותו בשדה "חיפוש חופשי".</li>
                        </ol>
                        
                        <p className='p2'>
                            <strong>בהצלחה! האגודה להתנדבות איתך לאורך כל הדרך זמינים לך במוקד השירות שלנו 1800-233-133</strong>
                        </p>

                    </div>

                   


                    <div className="closeBtnCont">
                        <Button className="closeBtnBig" onClick={() => setModalShow(false)} >סגירה</Button>
                    </div>

                </Modal.Body>
            </Modal>

    </div>
  )


}
