import React from 'react';
import SmallForm from './SmallForm';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import LoginQueryUrl from './LoginQueryUrl';

const RegisterIndex = ({ page, ...props }) => {
  console.log("RegisterIndex", page);

  const renderComponent = () => {
    switch (page) {
      case 'register':
        return <SmallForm {...props} />;
      case 'reSendPass':
        return <ForgotPassword {...props} />;
      case 'login':
        return <Login {...props} />;
      case 'loginQueryUrl':
        return <LoginQueryUrl {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className="clear">
      {renderComponent()}
    </div>
  );
};

export default RegisterIndex;
