import React, { useState } from 'react'
import ContentWrapper from '../../Components/loginPages/ContentWrapper'
import NavRight from './NavRight';
import NavMobile from './NavMobile';

const UserConsoleIndex = (props) => {
    const [changeSayarot, setChangeSayarot] = useState(false);

    const changeMenuright = (callBack) => {
        setChangeSayarot(callBack);
    }

    if(props.isMobile) {
        return (
            <div className={`body-container UserConsole Mobile ${props.page || ''}PClass`}>
                <NavMobile {...props} changeSayarot={changeSayarot} />
                <ContentWrapper 
                    {...props} 
                    changeMenuright={changeMenuright}
                />
            </div>
        )
    }

    return (
        <div className="body-container SystemPages Desktop">
            <div className="content-wrapper twoColumns">
                <NavRight {...props} changeSayarot={changeSayarot} />
                <div className="mainContent">
                    <ContentWrapper 
                        {...props}
                        changeMenuright={changeMenuright} 
                    />
                </div>
            </div>
        </div>
    )
}

export default UserConsoleIndex
