import React, { Component } from 'react'


import {Button} from 'react-bootstrap' //npm install react-bootstrap@next bootstrap@5.1.0

import getDataFromApi from '../../../Components/-Helpers-/api/getDataFromApi';
import loader from "../../../img/preLoader.gif";

import CustomFloatInput from '../../../Components/-Helpers-/forms/CustomFloatInput';
//import FloatInputPassword from '../../../Components/-Helpers-/forms/FloatInputPassword';

import ModalDefaul from '../../../Components/-Helpers-/ModalDefaul';
import { toast } from 'react-toastify';

export default class Sherut extends Component {

    constructor(props) {

        super(props);

        let user = JSON.parse(localStorage.getItem('userData'));
        

        let userApi = this.props.userData;
    
        console.log(user);

        this.state = {
            idno :  user.IDNO,
            SessionKey : user.SessionKey,

            
            Category : user.Category,
            YearYad : userApi.year_yad,
            

            loading : false,
            btnSendClass : 'success',
            responseEditRegister : false
        }

        console.log(this.state);
    
    
    
    }


    sendForm = (sendRules) => {


        this.setState({
            checkInputs : true,
            checkInputsPage : true
        })

        if(sendRules) {

            this.setState({
                loading: true
            });

            const sendObj = this.state;delete sendObj.responseEditRegister;
            delete sendObj.loading;delete sendObj.btnSendClass;
            delete sendObj.checkInputs;delete sendObj.checkInputsPage;


            this.sendtoAdmin('editUserData','users',sendObj,'responseEditRegister','users');

            // console.log(sendObj);
            
        } else {

            //console.log('NO send');
            //console.log(sendRules)
            toast.error('נא למלא שדות חובה');

            this.setState({
                btnSendClass : 'danger'
            })
        }

        

    }

    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);

        getData.then(getData =>this.setState({ [setStateName] : getData, loading : false}, () => {

            console.log(getData);

        }))
    }


    
    updateValue = (newValue) => {

        this.setState(newValue,()=>{
            console.log(this.state);
        })
    }


    render() {

        const sendRules = 
            this.state.Category &&
            this.state.YearYad
        ;

        const YearYadOptions = [
            { id: 'תשפ"ב', value: "שנה זו" },
            { id: 'תשפ"ג', value: "שנה הבאה" },
            { id: 'תשפ"ה', value: 'עוד שנתיים' }
        ]

    //    const CategoryOptions = [
    //         {id: 'של"מ', value: 'של"מ'},
    //         {id: 'ממלכתי דתי', value: 'ממלכתי דתי'},
    //         {id: 'מיעוטים', value: 'מיעוטים'},
    //         {id: 'צרכים מיוחדים', value: 'צרכים מיוחדים'},
    //         {id: 'בינלאומי', value: 'בינלאומי'}
    //     ] 

        const CategoryOptions = [
            { id: "1", value: 'חילוני' },
            { id: "2", value: "דתי" },
            { id: "3", value: "מיעוטים" },

          ];

        //const isMobile = this.props.isMobile ? this.props.isMobile : false;

        return (
            <div>
                <div className="firstRegister animate__animated animate__fadeIn">

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                <div className="inputs Sherut">
 
                    <div className="rows2 clear line">
                        <div className="input fullW">
                            <label style={{fontSize : '13px',color:'grey'}} >מסלול התנדבות</label>
                            <CustomFloatInput name='Category' selectOptions={CategoryOptions} disabled={true} updateValue={this.updateValue} value={this.state.Category} placeholder="בחירת מסלול התנדבות" cssClass='' validationRules={ {required: true} } typeInput='select' checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })}/>
                        </div>

                        <div className="input fullW">
                            <label style={{fontSize : '13px',color:'grey'}} >שנת שירות</label>
                            <CustomFloatInput  name='YearYad' selectOptions={YearYadOptions} disabled={true} updateValue={this.updateValue} value={this.state.YearYad} placeholder="שנת שירות" cssClass='' validationRules={ {required: true} } typeInput='select' checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })}/>
                        </div>
                        
                    </div>

                    <br/>

                    <div className="text-center" >
                        <br/>
                        {/* disabled={!flagSendForm} */}
                        <Button className="sendBlueBtn" onClick={() => this.sendForm(sendRules)} size="lg" variant={sendRules ? 'success animate__animated animate__bounceIn' : this.state.btnSendClass} >שמירת שינויים</Button>

                    </div>

                </div>


                {this.state.responseEditRegister && this.state.responseEditRegister.error ? 

                < ModalDefaul variant="error" params={ {title: 'שגיאה', text : this.state.responseEditRegister.error } } callBack={() => this.setState({ responseEditRegister : false })}  />

                : false }

                {this.state.responseEditRegister && this.state.responseEditRegister.ok ? 

                < ModalDefaul params={ {title: 'תודה רבה', text : this.state.responseEditRegister.ok } } callBack={() => this.setState({ responseEditRegister : false })}  />

                : false }

                </div>
            </div>
        )
    }
}
