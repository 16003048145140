import React, { useState, useRef, useCallback } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import SearchAutocompleteAndDbFocus from "./../../-Helpers-/api/SearchAutocompleteAndDbFocus";
import { FormValidator } from "./validation/FormValidator";
import { ValidationMessage } from "./validation/ValidationMessage";
import { ValidateForm } from "./validation/wholeFormValidation";
import { toast } from "react-toastify";

/**
 * CustomFloatInput component that provides various input types with floating labels and validation
 */
const CustomFloatInput = React.memo((props) => {
  const {
    value,
    selectOptions,
    noEnglish,
    validationRules,
    name,
    checkInputs,
    checked,
    dbParams,
    typeInput,
    cssClass,
    zIndexClass = "",
    disabled,
    placeholder,
    dateText,
    BirthDateText2,
    updateValue
  } = props;

  const [isError, setIsError] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const myInputRef = useRef();

  const rules = {
    inputValue: validationRules
  };

  const handleChange = useCallback((event) => {
    let newValue = event.target.value;

    if (noEnglish) {
      if (event.target.value !== newValue.replace(/[A-Za-z]/gi, "")) {
        toast.error("יש להקליד אותיות בעברית", {
          toastId: "customId",
        });
      }
      newValue = newValue.replace(/[A-Za-z]/gi, "");
    }

    updateValue({ [name]: newValue });
  }, [name, noEnglish, updateValue]);

  const isOK = useCallback((validationResult) => {
    const isValid = validationResult.inputValue === undefined || 
                   (validationRules.required ? value && value.length > 0 : true);
    setIsError(!isValid);
    return isValid;
  }, [validationRules, value]);

  React.useEffect(() => {
    if (checkInputs) {
      isOK({ inputValue: value });
      checked();
    }
  }, [checkInputs, isOK, checked]);

  const handleChangeSelect = useCallback((event) => {
    const newValue = event.target.value;
    updateValue({ [name]: newValue });
  }, [name, updateValue]);

  const setInputFocus = useCallback(() => {
    setShowInput(true);
    setTimeout(() => {
      myInputRef.current?.focus();
    });
  }, []);

  const handleSearchSelected = useCallback((item) => {
    const field = dbParams.valueField;
    const newValue = item[field];
    setIsError(false);
    updateValue({ [name]: newValue });
  }, [dbParams, name, updateValue]);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <div className={`input inputType${typeInput} ${zIndexClass}`}>
      {(typeInput === "text" || typeInput === "password" || typeInput === "number") && (
        <FormValidator
          data={{ inputValue: value }}
          rules={rules}
          isOK={isOK}
          validateForm={ValidateForm}
        >
          <div className="form-floating">
            <div className="password-input-container">
              <label htmlFor={name}>{placeholder}</label>
              <Form.Control
                className={`customFloatInput ${cssClass}`}
                isInvalid={isError}
                type={typeInput === "password" ? (showPassword ? "text" : "password") : typeInput}
                name="inputValue"
                id={name}
                disabled={disabled}
                onChange={handleChange}
                placeholder=" "
                value={value || ""}
              />
              {typeInput === "password" && (
                <button
                  type="button"
                  className="password-toggle-btn"
                  onClick={togglePasswordVisibility}
                  tabIndex="-1"
                >
                  <i className={`fas fa-eye${showPassword ? '-slash' : ''}`} />
                </button>
              )}
              <Form.Control.Feedback type="invalid">
                <ValidationMessage field="inputValue" />
              </Form.Control.Feedback>
            </div>
          </div>
        </FormValidator>
      )}

      {typeInput === "searchDB" && (
        <FormValidator
          data={{ inputValue: value }}
          rules={rules}
          isOK={isOK}
          validateForm={ValidateForm}
        >
          <div className={isError ? "customInputSearch errorClass" : "customInputSearch"}>
            <SearchAutocompleteAndDbFocus
              keys={["name"]}
              dbParams={{
                functionName: dbParams.function,
                controller: dbParams.controller,
              }}
              placeholder={placeholder}
              submit={handleSearchSelected}
              DBItems="app/getCities4Select"
              disabled={disabled}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            <ValidationMessage field="inputValue" />
          </Form.Control.Feedback>
        </FormValidator>
      )}

      {typeInput === "select" && (
        <FormValidator
          data={{ inputValue: value }}
          rules={rules}
          isOK={isOK}
          validateForm={ValidateForm}
        >
          <Form.Control
            as="select"
            className={`customSelectInput ${cssClass}`}
            isInvalid={isError}
            type={typeInput}
            name="inputValue"
            onChange={handleChangeSelect}
            value={value || ""}
            disabled={disabled}
          >
            <option value="">{placeholder}</option>
            {(selectOptions || []).map((item) => (
              <option value={item.id} key={item.id}>
                {item.value}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            <ValidationMessage field="inputValue" />
          </Form.Control.Feedback>
        </FormValidator>
      )}

      {typeInput === "date" && (
        <FormValidator
          data={{ inputValue: value }}
          rules={rules}
          isOK={isOK}
          validateForm={ValidateForm}
        >
          {(showInput || disabled) ? (
            <FloatingLabel controlId={name} label={placeholder}>
              <Form.Control
                style={{ marginTop: "16px", textAlign: "center" }}
                ref={myInputRef}
                className={`customFloatInput ${cssClass}`}
                isInvalid={isError}
                disabled={disabled}
                type="date"
                max="9999-12-31"
                name="inputValue"
                placeholder={dateText}
                onChange={(e) => {
                  handleChange(e);
                  setDirty(true);
                }}
                value={
                  name === "BirthDate"
                    ? value
                    : dirty
                    ? value
                    : BirthDateText2
                }
              />
              <Form.Control.Feedback type="invalid">
                <ValidationMessage field="inputValue" />
              </Form.Control.Feedback>
            </FloatingLabel>
          ) : (
            <div className="dateContFake">
              <div className="showPlaceHolder">
              {value ? placeholder : ""}
              </div>
              <button
                onClick={setInputFocus}
                className={!isError ? "fakeBtnInput" : "fakeBtnInput errorClass"}
                type="button"
              >
              {value ? dateText : placeholder}
              </button>
            </div>
          )}
        </FormValidator>
      )}
    </div>
  );
});

CustomFloatInput.displayName = 'CustomFloatInput';

export default CustomFloatInput;
