/* eslint-disable default-case */
import { RestUrls } from "../../Components/-Helpers-/config";
import { toast } from 'react-toastify';
import axios from 'axios';
import validator from "validator";
import moment from 'moment';

// Get user's timezone offset in minutes
const getUserTimezoneOffset = () => {
  return -new Date().getTimezoneOffset();
};

// Process time string to show in original timezone (+2)
const processTimeWithOriginalZone = (timeStr) => {
  if (!timeStr) return '';
  // Parse the UTC time and add 2 hours to show the original time
  const time = moment.utc(timeStr).add(2, 'hours');
  return time.format('HH:mm');
};

// Process Times array to show in original timezone
const processTimesWithOriginalZone = (times) => {
  return times.map(time => ({
    ...time,
    TimeF: time.TimeF ? processTimeWithOriginalZone(time.TimeF) : '',
    TimeT: time.TimeT ? processTimeWithOriginalZone(time.TimeT) : ''
  }));
};

export const getClockApiMaakav = async (url, sendObj, setLoading, setResponseData) => {
  setLoading(true);
  try {
    // Add timezone offset to the request
    const requestWithTimezone = {
      ...sendObj,
      TimezoneOffset: getUserTimezoneOffset()
    };

    const { data } = await axios.post(`${API_BASE_URL}${url}`, requestWithTimezone, {
      headers: { 'Content-Type': 'application/json' },
    });

    // Process the response data preserving original timezone
    if (data?.Shib) {
      data.Shib = data.Shib.map(report => ({
        ...report,
        Dates: report.Dates.map(date => ({
          ...date,
          Times: processTimesWithOriginalZone(date.Times)
        }))
      }));
    }
    
    setResponseData(data);
    return data;
  } catch (error) {
    handleApiError(error, setResponseData);
  } finally {
    setLoading(false);
  }
};

const API_BASE_URL = "https://vu-apiws-autosc.azurewebsites.net/api/";

const handleApiError = (error, setResponseData) => {
  console.error(error);
  setResponseData({});
  toast.error('שגיאה', { toastId: 'error' });
};

export const getFromApiSherutLeumi = async (url, sendObj, setLoading, setResponseData) => {
  setLoading(true);
  try {
    const response = await axios.post(`${RestUrls.sherutLeumiApi}${url}`, sendObj, {
      headers: { 'Content-Type': 'application/json' },
    });
    setResponseData(response);
  } catch (error) {
    handleApiError(error, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavStatus = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport) => {
  setLoading(true);
  try {
    // Add timezone offset to the request
    const requestWithTimezone = {
      ...sendObj,
      TimezoneOffset: getUserTimezoneOffset()
    };
    const { data } = await axios.post(`${API_BASE_URL}${url}`, requestWithTimezone, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (data?.Result !== 'Success') {
      setResponseData(false);
      return toast.error('שגיאה בקריאת סטטוס');
    }

    setSelectedReportType(data?.IsCurrIn ? data?.Typ : false);
    setTextAreaReport(data?.IsCurrIn ? data?.MoreInfo : false);
    // Cache the server response for timezone consistency
    if (data?.TimeF || data?.TimeT) {
      const serverResponse = {
        Dates: [{
          Times: [{
            TimeF: data.TimeF || '',
            TimeT: data.TimeT || ''
          }]
        }]
      };
      localStorage.setItem('lastServerResponse', JSON.stringify(serverResponse));
    }

    if (data?.IsCurrIn && data?.TimeF) {
      // Parse UTC time and add 2 hours to show original time
      const time = moment.utc(data.TimeF).add(2, 'hours');
      const timeValue = time.format('HH:mm');
      const hours = time.hours();
      const minutes = time.minutes();
      
      // Extract date components
      const year = time.format('YYYY');
      const month = time.format('MM');
      const day = time.format('DD');
      
      // Calculate weekday using local time conversion
      const getWeekday = (utcDateString) => {
        // Convert UTC to local time (Israel time, UTC+2)
        const localDate = new Date(new Date(utcDateString).getTime() + 2 * 60 * 60 * 1000);
        
        // Get weekday index (0 = Sunday, 6 = Saturday)
        const weekdayIndex = localDate.getDay();
        
        // Hebrew weekday names
        const hebrewWeekdays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
        
        // Return Hebrew weekday name
        return hebrewWeekdays[weekdayIndex];
      };
      
      setResponseData({
        api: data,
        time: timeValue,
        timeAllValues: {
          hours: parseInt(hours),
          minutes: parseInt(minutes),
          utcTime: data.TimeF // Store original UTC time
        },
        weekday: getWeekday(data.TimeF),
        day: `${day}/${month}/${year.substring(2)}` // DD/MM/YY format
      });
    }
  } catch (error) {
    handleApiError(error, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavOut = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport, setPercentWorked, setLastExit = false) => {
  setLoading(true);
  try {
    // Add timezone offset to the request
    const requestWithTimezone = {
      ...sendObj,
      TimezoneOffset: getUserTimezoneOffset()
    };
    const { data } = await axios.post(`${API_BASE_URL}${url}`, requestWithTimezone, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (data?.Result === "Error") {
      return toast.error('שגיאה');
    }

    setResponseData(false);
    setSelectedReportType(false);
    setTextAreaReport(false);
    setPercentWorked(false);
    toast.success('היציאה התקבלה בהצלחה');

    if (setLastExit && data?.TimeT) {
      // Parse UTC time and add 2 hours to show original time
      const time = moment.utc(data.TimeT).add(2, 'hours');
      const timeValue = time.format('HH:mm');
      setLastExit(timeValue);
    }
  } catch (error) {
    handleApiError(error, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavIn = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport) => {
  setLoading(true);
  try {
    // Add timezone offset to the request
    const requestWithTimezone = {
      ...sendObj,
      TimezoneOffset: getUserTimezoneOffset()
    };
    const { data } = await axios.post(`${API_BASE_URL}${url}`, requestWithTimezone, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (data?.Result !== 'Success') {
      return toast.error('שגיאה');
    } else if (data?.ErrorMessage === 'No maakav record') {
      return toast.error('שגיאה. לא קיים מעקב.');
    } else {
      toast.success('הכניסה התקבלה בהצלחה');
      const userJ = JSON.parse(localStorage.getItem("userData"));
      const newSendObj = { IDNumber: userJ.IDNO, SessionKey: userJ.SessionKey };

      await getClockApiMaakavStatus('v2/volunteer/MaakavStatus', newSendObj, setLoading,
        setResponseData, setSelectedReportType, setTextAreaReport);
    }
  } catch (error) {
    handleApiError(error, setResponseData);
  } finally {
    setLoading(false);
  }
};

// Hebrew month names mapping
const hebrewMonths = {
  1: 'ינואר',
  2: 'פברואר',
  3: 'מרץ',
  4: 'אפריל',
  5: 'מאי',
  6: 'יוני',
  7: 'יולי',
  8: 'אוגוסט',
  9: 'ספטמבר',
  10: 'אוקטובר',
  11: 'נובמבר',
  12: 'דצמבר'
};

export const showCurrentMonthName = (monthNumber, currentYear, action = false) => {
  try {
    const month = hebrewMonths[parseInt(monthNumber)];
    return action === 'monthNameOnly' ? month : `${month} ${currentYear}`;
  } catch (error) {
    console.error(error);
    toast.error('שגיאה');
    return '---';
  }
};

// Get server's current date by parsing server response
const getCurrentServerDate = () => {
    // Always use local date
    const now = new Date();
    const year = now.getFullYear().toString();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    return { 
        year, 
        month, 
        day, 
        fullDate: `${year}${month}${day}` 
    };
};

export const checkShowDays = (monthData, item) => {
    const today = getCurrentServerDate().fullDate;
    // Ensure consistent format with server data
    const itemDate = `${monthData.Year}${monthData.Month.padStart(2, '0')}${item.dayData.DOM.padStart(2, '0')}`;
    
    // Convert dates to numbers for comparison
    const todayNum = parseInt(today);
    const itemDateNum = parseInt(itemDate);
    
    console.log('Date comparison:', {
        today: todayNum,
        itemDate: itemDateNum,
        shouldShow: itemDateNum <= todayNum,
        monthData: monthData,
        item: item
    });
    
    // Show all days up to and including today
    return itemDateNum <= todayNum;
};

export const changeDay = (action, currentMonth, setCurrentMonth, currentYear, setCurrentYear, monthData = {}) => {
  const { year, month } = getCurrentServerDate();
  // Format dates for comparison
  const today = `${year}${month}`;
  const currentDate = `${currentYear}${currentMonth.toString().padStart(2, '0')}`;
  const flagMore = currentDate < today;

  switch (action) {
    case 'checkActive':
      return !flagMore;
    case 'checkEditMode':
      return !monthData?.ReportConfirmationDate;
    case 'back':
      if (currentMonth > 1) {
        setCurrentMonth(currentMonth - 1);
      } else {
        setCurrentYear(currentYear - 1);
        setCurrentMonth(12);
      }
      break;
    case 'forward':
      if (flagMore) {
        if (currentMonth < 12) {
          setCurrentMonth(currentMonth + 1);
        } else {
          setCurrentYear(currentYear + 1);
          setCurrentMonth(1);
        }
      }
      break;
  }
};

export const checkhasPerutDivuach = (selectedReportType, textAreaReport) =>
  (selectedReportType === '2' || selectedReportType === '3') || (textAreaReport && textAreaReport !== 'False');

export const checkTextAreaReportContent = (selectedReportType, textAreaReport) => {
  if (checkhasPerutDivuach(selectedReportType, textAreaReport)) {
    if (textAreaReport && validator.isNumeric(textAreaReport)) {
      toast.error('יש לרשום גם מלל');
      return false;
    }
    if (/^[A-Za-z0-9]*$/.test(textAreaReport)) {
      toast.error('אסור מילוי רק תווים באנגלית');
      return false;
    }
    return true;
  }
  return true;
};