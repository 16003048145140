import React, { useState, useEffect } from 'react';
import { RestUrls } from "../../../Components/-Helpers-/config";
import ShowDataInRowPlaces from './ShowDataInRowPlaces';
import ShowMoreDataPlaces from './ShowMoreDataPlaces';
import GalleryPictures from './GalleryPictures';
import { isMobile } from "react-device-detect";

import blueBtnInfo from "../../../img/sherut-leumi/svg/sherutPlaces/card/blueBtnInfo.svg";
import favorites from "../../../img/sherut-leumi/svg/sherutPlaces/card/favorites.svg";
import favorites_on from "../../../img/sherut-leumi/svg/sherutPlaces/card/favorites_on.svg";

const SearchResultRow = ({ item, showOpen = false, favoritesPlaces, chageFavorites }) => {
  const [showMoreData, setShowMoreData] = useState(showOpen);
  const [fPlacesArray, setFPlacesArray] = useState(favoritesPlaces);

  const addRemoveFavorites = (item) => {
    let updatedFavorites = [...favoritesPlaces];
    
    if (!updatedFavorites.includes(item.id)) {
      updatedFavorites.push(item.id);
      setFPlacesArray(updatedFavorites);
      localStorage.setItem('favoritesPlaces', updatedFavorites.join(','));
    } else {
      const index = updatedFavorites.findIndex(id => id === item.id);
      if (index !== -1) {
        updatedFavorites.splice(index, 1);
        setFPlacesArray(updatedFavorites);
        localStorage.setItem('favoritesPlaces', updatedFavorites);
      }
    }

    chageFavorites(updatedFavorites);
  };

  const urlPic = `${RestUrls.pagesPictures}/noPic.jpg?v=1`;
  const is_Favorite = fPlacesArray.includes(item.id);
  return (
    <div className={`searchRow GreyPanel ${is_Favorite ? 'activeFavorite' : ''}`}>
      <div className='tableSearchRow'>
        <div className="coltableSearch picCol">
          {item.pictures?.length > 0 ? (
            <GalleryPictures pictures={item.pictures} />
          ) : (
            <figure style={{backgroundImage: `url('${urlPic}')`}} />
          )}
        </div>

        <div className="coltableSearch dataCol">
          <ShowDataInRowPlaces item={item} />
        </div>

        {!isMobile && (
          <div className="coltableSearch BtnsCol">
            <div className="sherutPlaceButtons">
              <button onClick={() => setShowMoreData(!showMoreData)}>
                <img src={blueBtnInfo} alt='לפרטים נוספים' />
                <span>
                  {!showMoreData ? 'לפרטים נוספים' : 'סגירת פרטים נוספים'}
                </span>
              </button>

              <button 
                className={is_Favorite ? 'active' : ''} 
                onClick={() => addRemoveFavorites(item)}
              >
                <img src={is_Favorite ? favorites_on : favorites} alt='מועדפים' />
                <span>
                  {is_Favorite ? 'הסרה ממועדפים' : 'הוספה למועדפים'}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>

      {isMobile && (
        <div className="BtnsCol">
          <div className="sherutPlaceButtons">
            <button onClick={() => setShowMoreData(!showMoreData)}>
              <img src={blueBtnInfo} alt='לפרטים נוספים' />
              <span>
                {!showMoreData ? 'לפרטים נוספים' : 'סגירת פרטים '}
              </span>
            </button>

            <button 
              className={is_Favorite ? 'active' : ''} 
              onClick={() => addRemoveFavorites(item)}
            >
              <img src={favorites} alt='מועדפים' />
              <span>
                {is_Favorite ? 'הסרה ממועדפים' : 'הוספה למועדפים'}
              </span>
            </button>
          </div>
        </div>
      )}

      {showMoreData && (
        <div className="showMoreData mData animate__animated animate__fadeIn">
          <ShowMoreDataPlaces item={item} isMobile={isMobile} />
        </div>
      )}
    </div>
  );
};

export default SearchResultRow;
