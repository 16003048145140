    import React, { Fragment, useEffect, useState } from 'react'
    import { useRef } from 'react';
    import moment from 'moment';
    import { map, size, some } from 'lodash';
    import { Form } from 'react-bootstrap'
    import { toast } from 'react-toastify';

    // Icons
    import infoRed from "../../img/icons/clockPage/infoRed.svg";
    import add from "../../img/icons/clockPage/add.svg";
    import question from "../../img/icons/clockPage/question.svg";
    import failed from "../../img/icons/clockPage/failed.svg";
    import loader from "../../img/preLoader.gif";

    // Components
    import OpenCLoseTextAreaReport from './OpenCLoseTextAreaReport';
    import { checkData4Save, getAbsenceList, sendChangesToApi } from './editClockFunctions';

    // Format date as YYYYMMDD
    function formatDateYYYYMMDD(year, month, day) {
        try {
            // Get current date for validation
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();

            // Handle 2-digit years and validation
            let numYear = parseInt(String(year), 10);
            if (numYear < 100) {
                numYear = 2000 + numYear;
            }
            
            // Extensive validation and error handling
            if (Math.abs(numYear - currentYear) > 1) {
                numYear = currentYear;
            }
            
            // Format with proper padding
            const formattedDate = `${numYear}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
            return formattedDate;
        } catch (error) {
            console.error('Error formatting date:', error);
            return '';
        }
    }

    // Add these utility functions after formatDateYYYYMMDD
    function validateTimeRange(timeF, timeT) {
        if (!timeF || !timeT) return true; // Allow empty times
        
        try {
            const [hoursF, minutesF] = timeF.split(':').map(Number);
            const [hoursT, minutesT] = timeT.split(':').map(Number);
            
            // Validate time components
            if (isNaN(hoursF) || isNaN(minutesF) || isNaN(hoursT) || isNaN(minutesT)) {
                console.error('Invalid time components:', { hoursF, minutesF, hoursT, minutesT });
                toast.error('זמן לא חוקי');
                return false;
            }
            
            // Validate ranges
            if (hoursF < 0 || hoursF > 23 || minutesF < 0 || minutesF > 59 ||
                hoursT < 0 || hoursT > 23 || minutesT < 0 || minutesT > 59) {
                console.error('Time components out of range:', { hoursF, minutesF, hoursT, minutesT });
                toast.error('זמן מחוץ לטווח חוקי');
                return false;
            }
            
            // Convert to minutes for comparison
            const totalMinutesF = hoursF * 60 + minutesF;
            const totalMinutesT = hoursT * 60 + minutesT;
            
            // Check if end time is after start time
            if (totalMinutesT <= totalMinutesF) {
                console.error('End time must be after start time:', { timeF, timeT });
                toast.error('שעת היציאה חייבת להיות מאוחרת משעת הכניסה');
                return false;
            }
            
            return true;
        } catch (error) {
            console.error('Error validating time range:', error);
            toast.error('שגיאה בבדיקת טווח זמנים');
            return false;
        }
    }

    // Check if a given time is in the future
    function isFutureTime(timeStr, dateStr) {
        if (!timeStr || !dateStr) return false;
        try {
            const now = new Date();
            const [hours, minutes] = timeStr.split(':');
            const checkDate = new Date(dateStr);
            checkDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);

            return checkDate > now;
        } catch (error) {
            console.error('Error checking future time:', error);
            return false;
        }
    }

    // Create UTC ISO string from time input
    function createUTCString(timeStr, dateStr, dateComponents) {
        if (!timeStr || !dateStr) return '';
        try {
            const year = dateComponents?.year || new Date().getFullYear();
            const month = dateComponents?.month || (new Date().getMonth() + 1);
            const day = dateComponents?.day || new Date().getDate();
            
            // Format the date string properly
            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            
            // Check for future time
            if (isFutureTime(timeStr, formattedDate)) {
                toast.error('לא ניתן לדווח על שעה עתידית');
                return '';
            }
            
            // Normalize the time to the correct date
            const normalizedTime = normalizeTimeToDate(timeStr, formattedDate);
            if (!normalizedTime) {
                toast.error('שגיאה בעיבוד הזמן');
                return '';
            }
            
            return normalizedTime;
        } catch (error) {
            console.error('Error creating UTC string:', error);
            toast.error('שגיאה ביצירת זמן');
            return '';
        }
    }

    function normalizeTimeToDate(timeStr, dateStr) {
        if (!timeStr || !dateStr) return '';
        try {
            const [hours, minutes] = timeStr.split(':').map(Number);
            const date = new Date(dateStr);
            
            // Set the time components while preserving the original date
            const newDate = new Date(date);
            newDate.setHours(hours, minutes, 0, 0);
            
            // Ensure we're working with the correct date
            const utcString = newDate.toISOString();
            console.log('Normalized time:', {
                input: { timeStr, dateStr },
                output: utcString,
                localTime: newDate.toLocaleString(),
                originalDate: date.toLocaleString()
            });
            
            return utcString;
        } catch (error) {
            console.error('Error normalizing time:', error);
            return '';
        }
    }

    function checkTimeOverlap(times) {
        if (!times || times.length < 2) return true;
        
        try {
            // Filter out entries without both times
            const validTimes = times.filter(t => t.TimeF && t.TimeT);
            
            if (validTimes.length < 2) return true;
            
            // Sort times by TimeF
            const sortedTimes = [...validTimes].sort((a, b) => {
                const timeA = new Date(a.TimeF);
                const timeB = new Date(b.TimeF);
                
                if (isNaN(timeA.getTime()) || isNaN(timeB.getTime())) {
                    console.error('Invalid date in sorting:', { timeA, timeB });
                    return 0;
                }
                
                return timeA - timeB;
            });
            
            console.log('Checking time overlap for sorted times:', sortedTimes);
            
            // Check for overlaps
            for (let i = 0; i < sortedTimes.length - 1; i++) {
                const currentEnd = new Date(sortedTimes[i].TimeT);
                const nextStart = new Date(sortedTimes[i + 1].TimeF);
                
                if (isNaN(currentEnd.getTime()) || isNaN(nextStart.getTime())) {
                    console.error('Invalid date in overlap check:', { 
                        current: sortedTimes[i],
                        next: sortedTimes[i + 1]
                    });
                    continue;
                }
                
                if (currentEnd >= nextStart) {
                    console.error('Time overlap detected:', {
                        current: sortedTimes[i],
                        next: sortedTimes[i + 1]
                    });
                    toast.error('קיימת חפיפה בין זמני הדיווח');
                    return false;
                }
            }
            
            return true;
        } catch (error) {
            console.error('Error checking time overlap:', error);
            toast.error('שגיאה בבדיקת חפיפת זמנים');
            return false;
        }
    }

    export default function EditMyDay(props) {
        const [currenPage, ] = useState('editHours')
        return (
            <div className='editMyDay'>
                {currenPage === 'editHours' && <EditHours {...props} />}
            </div>
        )
    }

    function EditHours(props) {
        const {item, monthData, closeMe, reloadData } = props
        const [showInfo, setShowInfo] = useState(false);
        const [loading, setLoading] = useState(false);
        const [absenceList, setAbsenceList] = useState(false);
        const [absence, setAbsence] = useState(item?.isAbsence ? item.isAbsence : '');
        const [showAbsence, setShowAbsence] = useState(item?.isAbsence ? true : false);
        let userJ = JSON.parse(localStorage.getItem("userData"));
        const [inOutDays, setInOutDays] = useState(item.items);

        useEffect(() => {
            if(!loading && !absenceList && showAbsence) {
                try {
                    // Get current date for comparison
                    const currentDate = new Date();
                    
                    // Extract and validate year
                    let year = monthData?.Year ? parseInt(String(monthData.Year), 10) : currentDate.getFullYear();
                    if (year < 100) {
                        year = 2000 + year;
                    }
                    
                    // Extract and validate month and day
                    const month = monthData?.Month || item?.dayData?.Month || (currentDate.getMonth() + 1);
                    const day = item?.dayData?.DOM || currentDate.getDate();
                    
                    console.log('Date components:', { 
                        year,
                        month,
                        day,
                        monthDataYear: monthData?.Year,
                        monthDataMonth: monthData?.Month,
                        itemDayData: item?.dayData 
                    });

                    // Validate date components
                    if (month < 1 || month > 12 || day < 1 || day > 31) {
                        console.error('Invalid date components:', { month, day });
                        toast.error('תאריך לא חוקי');
                        return;
                    }

                    // Format date using moment for consistency
                    const formattedDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('YYYYMMDD');
                    if (!formattedDate) {
                        console.error('Failed to format date');
                        toast.error('שגיאה בעיבוד התאריך');
                        return;
                    }

                    console.log('Formatted date for API:', formattedDate);

                    const sendObj = {
                        idno: userJ.IDNO,
                        SessionKey: userJ.SessionKey,
                        Date: formattedDate,
                    };

                    console.log('API request payload:', sendObj);
                    
                    getAbsenceList('v2/volunteer/Available_reasons', sendObj, setLoading, (response) => {
                        console.log('API Response:', response);
                        if (response?.Result === 'Error') {
                            console.error('API Error:', response?.ErrorMessage);
                            toast.error(response?.ErrorMessage || 'שגיאה בשליפת נתוני העדרות');
                            return;
                        }
                        setAbsenceList(response);
                    });
                } catch (error) {
                    console.error('Error in absence list fetch:', error);
                    toast.error('שגיאה בשליפת נתוני העדרות');
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showAbsence])

        const addDay = () => { 
            try {
                // Generate a unique timestamp-based ID
                const timestamp = Date.now();
                const randomSuffix = Math.floor(Math.random() * 1000);
                
                // Use a safe default if monthData.ReportID is not available
                const baseId = (monthData?.ReportID && monthData.ReportID !== 'undefined') 
                    ? monthData.ReportID 
                    : `report_${timestamp}`;
                    
                const newIndex = (inOutDays?.length || 0) + 1;
                const newId = `${baseId}_${newIndex}_${randomSuffix}`;
                
                console.log('Generated new day ID:', {
                    baseId,
                    newIndex,
                    newId,
                    existingDays: inOutDays?.length,
                    timestamp,
                    randomSuffix,
                    monthDataReportID: monthData?.ReportID
                });

                const newItem = {
                    id: newId,
                    api: {
                        LateReportMtnF: false,
                        LateReportMtnT: false,
                        LateReportRakF: false,
                        LateReportRakT: false,
                        MoreInfo: "",
                        TimeF: "",
                        TimeT: "",
                        Typ: ""
                    }
                };

                // Validate the new item before adding
                if (!newItem.id || typeof newItem.id !== 'string') {
                    throw new Error('Invalid ID generated');
                }

                toast.success('אפשרויות נוספות לדיווח בסוף הרשימה');
                setInOutDays(prevDays => [...(prevDays || []), newItem]);
            } catch (error) {
                console.error('Error adding new day:', error);
                toast.error('שגיאה בהוספת דיווח חדש');
            }
        }

        const cancelChangesHandler = () => { 
            toast.warning('השינוים לא נשמרו');
            closeMe('bottom', false);
        }

        const saveChanges = () => { 
            const checkSave = checkData4Save(allChanges);
            if(checkSave) {
                const sendObj = {
                    IDNumber: userJ.IDNO,
                    SessionKey: userJ.SessionKey,
                };
                sendChangesToApi('v2/volunteer/maakavupdate', sendObj, setLoading,allChanges,closeMe,reloadData);
            }
        }

        const [allChanges, setAllChanges ] = useState(deafaultValues(monthData,item));
        const [areChanges, setAreChanges] = useState(false);

        useEffect(() => {
            const oldObj = allChanges.Dates[0];
            oldObj.Reson = absence;
            setAllChanges({...allChanges, Dates : [oldObj] } )
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [absence])
        
        const changeAbsence = (value) => {
            setAreChanges(true);
            setAbsence(value);
        }

        const changeMyDay = (changes) => { 
            setAreChanges(true);
            
            setAllChanges(prevAllChanges => {
                // Make a deep copy of the state
                const newState = {
                    ...prevAllChanges,
                    Dates: [...prevAllChanges.Dates]
                };
                
                // Get current Times array or initialize if empty
                const currentTimes = newState.Dates[0].Times || [];
                
                // Find if the item already exists
                const itemIndex = currentTimes.findIndex(item => item.id === changes.id);
                
                // Create new Times array
                let newTimes;
                if (itemIndex >= 0) {
                    // Update existing item
                    newTimes = currentTimes.map(item => 
                        item.id === changes.id ? {...changes} : item
                    );
                } else {
                    // Add new item
                    newTimes = [...currentTimes, changes];
                }
                
                // Update Times array in state
                newState.Dates[0] = {
                    ...newState.Dates[0],
                    Times: newTimes
                };
                
                console.log('Updated state:', newState);
                return newState;
            });
        }

        const cancelAbs = () => { 
            setAreChanges(true);
            setAbsence('');
            setShowAbsence(false);
        }

        const [noEditQuota, setNoEditQuota] = useState(false);

        useEffect(() => {
        if (
                (monthData?.LateReportUsed >= monthData?.LateReportQuota) && 
                !userJ?.AttendanceReportOnline  ) {
                setNoEditQuota(true);
                toast.error('מכסת הדיווחים באיחור נוצלה במלואה. נא לפנות לרכזת להמשך טיפול');
            } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return(
            <Fragment>
                <img  src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

                <div className='headerOpenDown'>
                    <h2>דיווח ידני</h2>
                    <p>{item.title}</p>
                </div>

                { !userJ?.AttendanceReportOnline && monthData?.LateReportUsed !== "null" &&
                <div className={`reportEditCount ${noEditQuota ? 'animate__animated animate__flash animate__delay-2s' : ''}`}>
                    <img onClick={ ()=>setShowInfo(!showInfo) } src={ infoRed } alt='infoRed' />
                    <strong style={ noEditQuota ? {color: 'red'} : {} }>נשארו עוד <span>{monthData?.LateReportQuota - monthData?.LateReportUsed}</span> מתוך {monthData?.LateReportQuota} דיווחים באיחור</strong>
                </div> }

                <div className='buttonsEditReportDay'>
                    <button onClick={ addDay }>
                        <img src={add} alt='add' />
                        <span>הוספת דיווח</span>
                    </button>

                    <button onClick={()=> setShowAbsence(!showAbsence)}>
                        <img src={question} alt='question' />
                        <span>סיבת העדרות</span>
                    </button>
                </div>

                <div className='daysitemsCont'>
                    {showInfo ? <ShowInfo monthData = {monthData} setShowInfo = {setShowInfo} /> :
                    <Fragment>
                        {(showAbsence && absenceList) && <div className='isAbsence animate__animated animate__fadeIn'>
                            <h3>סיבת העדרות: </h3>
                            <div className='selectCont'>
                                <Form.Control 
                                    as="select"
                                    className={"chooseReportSelect"}
                                    onChange={(e) => changeAbsence(e.target.value) } 
                                    value={absence}
                                >
                                    <option value="">סיבת העדרות</option>
                                    {map(absenceList, item => {
                                        return(<option value={item} key= {item} >{item}</option>)
                                    })}
                                </Form.Control>
                            </div>

                            { absence && <button className='cancelAbs' onClick={cancelAbs} >
                                <img src={failed} alt='ביטול סיבת העדרות' />
                                <span>לחצו לביטול סיבת העדרות</span>
                            </button> }
                        </div> }

                        {map(inOutDays, dayItem => {
                            return(<DayItem 
                                key={ dayItem.id } 
                                dayItem={ dayItem } 
                                monthData={monthData}
                                item={item}
                                changeMyDay={(changes) => changeMyDay(changes)} 
                            />)
                        })}
                    </Fragment> }
                </div>
                
                {!noEditQuota ? <div className='saveChangesBtns'>
                    {areChanges && <button className='saveBtn' onClick={ saveChanges }>שמירת שינויים</button>}
                    <button className='cancelBtn' onClick={ cancelChangesHandler }>
                        { !areChanges ? <span>סגירת חלון</span> : <span>ביטול שינויים</span> }
                    </button>
                </div> : <div className='saveChangesBtns'>
                    <button className='cancelBtn' onClick={ cancelChangesHandler }>סגירת חלון</button>
                </div> }
            </Fragment>        
        )
    }

    function DayItem(props) {
        const {dayItem, changeMyDay, monthData, item} = props
        const refIn = useRef(null);
        const refOut = useRef(null);

        // Extract time from UTC string (HH:mm format)
        const convertUTCToDisplay = (utcTimeStr) => {
            if (!utcTimeStr) return '';
            try {
                const date = new Date(utcTimeStr);
                if (isNaN(date.getTime())) {
                    console.error('Invalid UTC time string:', utcTimeStr);
                    return '';
                }
                const localHours = date.getHours().toString().padStart(2, '0');
                const localMinutes = date.getMinutes().toString().padStart(2, '0');
                return `${localHours}:${localMinutes}`;
            } catch (error) {
                console.error('Error extracting time:', error);
                return '';
            }
        };

        // Initialize with API values in local format for display
        const [dayIn, setDayIn] = useState(() => {
            if (dayItem?.api?.TimeF) {
                return convertUTCToDisplay(dayItem.api.TimeF);
            }
            return '';
        });
        
        const [dayOut, setDayOut] = useState(() => {
            if (dayItem?.api?.TimeT) {
                return convertUTCToDisplay(dayItem.api.TimeT);
            }
            return '';
        });

        const [selectedReportType, setSelectedReportType] = useState(dayItem?.api?.Typ || false)
        const [textAreaReport, setTextAreaReport] = useState(dayItem?.api?.MoreInfo || false)

        //send changes
        useEffect(() => {
            // Get current date for defaults
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            
            // Extract date components with proper validation
            const dateComponents = {
                year: monthData?.Year ? parseInt(String(monthData.Year), 10) : currentYear,
                month: item?.dayData?.Month ? parseInt(String(item.dayData.Month), 10) : (currentDate.getMonth() + 1),
                day: item?.dayData?.DOM ? parseInt(String(item.dayData.DOM), 10) : currentDate.getDate()
            };

            // Fix year if it's 2 digits
            if (dateComponents.year < 100) {
                dateComponents.year = 2000 + dateComponents.year;
            }

            // If year is significantly off, use current year
            if (Math.abs(dateComponents.year - currentYear) > 1) {
                console.warn('Year adjusted to current year:', {
                    original: dateComponents.year,
                    current: currentYear
                });
                dateComponents.year = currentYear;
            }
            
            console.log('Date components for processing:', dateComponents);
            
            // Skip processing if both times are empty
            if (!dayIn && !dayOut) {
                console.log('No time inputs to process, skipping update');
                return;
            }
            
            // Validate the time range first
            if (!validateTimeRange(dayIn, dayOut)) {
                return;
            }
            
            const formattedDate = formatDateYYYYMMDD(
                dateComponents.year,
                dateComponents.month,
                dateComponents.day
            );
            
            if (!formattedDate) {
                console.error('Failed to format date with components:', dateComponents);
                toast.error('שגיאה בעיבוד התאריך');
                return;
            }
            
            // Format date for time normalization (YYYY-MM-DD)
            const date = `${dateComponents.year}-${dateComponents.month.toString().padStart(2, '0')}-${dateComponents.day.toString().padStart(2, '0')}`;
            
            console.log('Processing times with date:', {
                formattedDate,
                normalizedDate: date,
                dayIn,
                dayOut
            });
            
            const timeF = dayIn ? createUTCString(dayIn, date, dateComponents) : '';
            const timeT = dayOut ? createUTCString(dayOut, date, dateComponents) : '';
            
            if ((dayIn && !timeF) || (dayOut && !timeT)) {
                console.error('Failed to create UTC strings:', { dayIn, dayOut, timeF, timeT });
                toast.error('שגיאה בעיבוד הזמנים');
                return;
            }
            
            const newItemObj = {
                id: dayItem.id,
                TimeF: timeF,
                TimeT: timeT,
                Typ: selectedReportType || '',
                MoreInfo: textAreaReport || '',
                LateReportMtnF: dayItem.api.LateReportMtnF || false,
                LateReportRakF: dayItem.api.LateReportRakF || false,
                LateReportMtnT: dayItem.api.LateReportMtnT || false,
                LateReportRakT: dayItem.api.LateReportRakT || false,
            };
            
            console.log('Final item object:', newItemObj);
            
            // Get the current times from the parent component's state
            const currentTimes = item.items.map(i => ({
                TimeF: i.api.TimeF || '',
                TimeT: i.api.TimeT || '',
                id: i.id
            }));
            
            // Create the updated times array for overlap checking
            const updatedTimes = currentTimes.map(t => 
                t.id === newItemObj.id ? newItemObj : t
            );
            
            // Check for overlaps before updating
            if (!checkTimeOverlap(updatedTimes)) {
                return;
            }
            
            changeMyDay(newItemObj);
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dayIn, dayOut, selectedReportType, textAreaReport]);
        
        const setInputFocus = (inOut) => { 
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const currentLocalTime = `${hours}:${minutes}`;

            console.log('Setting current time:', {
                localTime: currentLocalTime,
                fullLocal: now.toLocaleString()
            });

            if(inOut === 'in') {
                setDayIn(currentLocalTime);
                setTimeout(() => { refIn.current.focus(); }, 100);
            } else if(inOut === 'out') {
                setDayOut(currentLocalTime);
                setTimeout(() => { refOut.current.focus(); }, 100);
            }
        }

        return(
            <div className='editHoursItem' >
                <OpenCLoseTextAreaReport 
                    isEdit={true} dayItem={dayItem}
                    selectedReportType={selectedReportType} setSelectedReportType={setSelectedReportType}
                    textAreaReport={textAreaReport} setTextAreaReport={setTextAreaReport}
                />

                <ul className='inOutItems'>
                    <li className='in'>
                        <span className={dayIn ? '' : 'error'} >שעת כניסה</span>
                        
                        { dayIn ? <input className='changeHour' type='time' value={dayIn} ref={refIn} onChange={(e) => setDayIn(e.target.value)} /> : 
                            <p onClick={()=>  setInputFocus('in')} className='error'>--:--</p>
                        }
                    </li>
                    <li className='separate'>-</li>
                    <li className='out'>
                        <span className={dayOut ? '' : 'error'} >שעת יציאה</span> 

                        { dayOut ? <input className='changeHour' type='time' ref={refOut} value={dayOut} onChange={(e) => setDayOut(e.target.value)} /> : 
                            <p onClick={()=>  setInputFocus('out')} className='error'>--:--</p>
                        }
                    </li>
                </ul>
            </div>
        );
    }

    function ShowInfo(props) {
        const { monthData, setShowInfo } = props
        return(
            <div className='ShowInfo animate__animated animate__fadeIn'>
                <div className='contInfo'>
                    <h2>דיווחים באיחור</h2>
                    <p>הרשות לשירות לאומי-אזרחי מחייבת כל מתנדב/ת לדווח נוכחות בזמן אמת.</p>
                    <p> דיווח שעה שחורגת ב-30 דקות ומעלה ממועד הכניסה/יציאה. בפועל, מוגדר כדיווח באיחור ולא יתקבל.</p>
                    <p>בנוסף, לא יתקבל דיווח על שעה עתידית.</p>
                    <p>סיבת היעדרות ניתן לדווח לכל אורך היום עצמו, מלבד חופש. מחלה, אותן ניתן לדווח בכל החודש.</p>
                    <p>{monthData?.LateReportQuota} פעמים, בכל חודש האגודה להתנדבות מאפשרת לדווח באיחור.</p>
                    <p>כל שעת כניסה/יציאה או סיבת היעדרות, נפרת כדיווח אחד.</p>
                    <p> במידה וניצלת מלוא המכסה, הדיווח באיחור לא יתקבל ויש. "לפנות לכז/ת להמשך טיפול.</p>
                </div>
                <button onClick={ ()=>setShowInfo(false) }>
                    <span>אישור</span>
                </button>
            </div>
        )
    }

    function deafaultValues(monthData,item) {
        const returnObj = {
            ...monthData,
            Dates: [
                {
                    'Times' : [],
                    ...item.dayData
                },
            ],
        }
        return returnObj
    }
