import React, { Component } from 'react'
import SearchResultRowPlaces from './SearchResultRowPlaces';

export default class SearchResultsPlaces extends Component {

    render() {

        //console.log(this.props.items);

        return (

            <div>

                {this.props.items ? <div className="SearchResults">

                    {this.props.items.map(item => { if (true) {
                  
                        return  <SearchResultRowPlaces favoritesPlaces={this.props.favoritesPlaces} chageFavorites={(chageFavorites) => this.props.chageFavorites(chageFavorites) } item={item} showOpen={this.props.showOpen} key={item.id} />
                    } else return false}) }
                    
                </div> : <h2 className="noResults" >שגיאה</h2> }

            </div>
        )

    }
}
