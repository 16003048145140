import React, { Component } from 'react'

export default class SearchCategory extends Component {
    render() {
        console.log('Current page:', this.props.currentPage);
        
        return (
            <div className="changePage">
                <div className='buttonsCont'>
                    <button 
                        className={this.props.currentPage !== 'mySayarot' ? 'active' : ''} 
                        onClick={() => {
                            console.log('Changing to pageSayarot');
                            this.props.changePage('pageSayarot');
                        }}
                    >
                        הסיירות
                    </button>
                    <button 
                        className={this.props.currentPage === 'mySayarot' ? 'active' : ''} 
                        onClick={() => {
                            console.log('Changing to mySayarot');
                            this.props.changePage('mySayarot');
                        }}
                    >
                        הסיירות שלך
                    </button>
                </div>
            </div>
        )
    }
}
