import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";
import SiteConnector from "./SiteConnector";
import { getAllUrlParams } from "../-Helpers-/UrlParameters";
import MekomotSherutSearchOnly from "../../Pages/MekomotSherut/MekomotSherutSearchOnly";

const GetPage = ({ location }) => {
  const urlParams = getAllUrlParams(window.location.search);

  // Handle iframe cases first
  if (urlParams?.iframe === "1") {
    return (
      <Router>
        <Switch>
          <Route
            path="/"
            component={() =>
              urlParams?.menuonly === "1" ? (
                <MekomotSherutSearchOnly isMobile={!!isMobile} />
              ) : (
                <SiteConnector
                  search={location.search}
                  page="mekomotSherutPages"
                  isMobile={!!isMobile}
                />
              )
            }
          />
        </Switch>
      </Router>
    );
  }

  // Check if userData is undefined and handle that case
  const userDataStr = localStorage.getItem("userData");
  if (userDataStr === "undefined") {
    localStorage.removeItem("userData");
    window.open("/login", "_self");
    return null;
  }

  if (location.search === "?logout=1") {
    localStorage.removeItem("userData");
    window.open("/login", "_self");
  } else if (location.search === "?login=1") {
    localStorage.setItem("userData", JSON.stringify({ SessionKey: "s" }));
    localStorage.removeItem("rakazid");
    localStorage.removeItem("sayeretid");
    window.open("/userConsole", "_self");
  }

  // Safely check login status
  const isLoggedIn = userDataStr && userDataStr !== "undefined" && userDataStr !== "";

  if (isLoggedIn) {
    return (
      <Router>
        <Switch>
          <Route
            path="/sherutPlaces"
            component={() => (
              <SiteConnector
                search={location.search}
                page="mekomotSherutPages"
                isMobile={!!isMobile}
              />
            )}
          />
          <Route
            path="/"
            component={() => (
              <SiteConnector
                page="userConsolePages"
                isMobile={!!isMobile}
              />
            )}
          />
        </Switch>
      </Router>
    );
  } else if (location.search !== "?logout=1") {
    return (
      <Router>
        <Switch>
          <Route
            path="/register"
            component={() => (
              <SiteConnector
                page="register"
                isMobile={!!isMobile}
              />
            )}
          />
          <Route
            path="/login"
            component={() => (
              <SiteConnector
                page="login"
                isMobile={!!isMobile}
              />
            )}
          />
          <Route
            path="/reSendPass"
            component={() => (
              <SiteConnector
                page="reSendPass"
                isMobile={!!isMobile}
              />
            )}
          />
          <Route
            path="/loginQueryUrl"
            component={() => (
              <SiteConnector
                page="loginQueryUrl"
                isMobile={!!isMobile}
              />
            )}
          />
          <Route
            path="/sherutPlaces"
            component={() => (
              <SiteConnector
                search={location.search}
                page="mekomotSherutPages"
                isMobile={!!isMobile}
              />
            )}
          />
          <Route
            path="/"
            component={() => (
              <SiteConnector
                page="login"
                isMobile={!!isMobile}
              />
            )}
          />
        </Switch>
      </Router>
    );
  }
  return null;
};

export default GetPage;