import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { Accordion } from "react-bootstrap";

import rightArrow from "../../img/icons/clockPage/right-arrow.svg";
import sherut from "../../img/icons/clockPage/sherut.svg";
import sicum from "../../img/icons/clockPage/sicum.svg";
import left from "../../img/icons/clockPage/left.svg";
import right from "../../img/icons/clockPage/right.svg";
import loader from "../../img/preLoader.gif";

import { changeDay, showCurrentMonthName } from "./clockFunctions";
import CardDayReport from "./CardDayReport";
import { confirmMonth, getMonthData, getSummaryData } from "./editClockFunctions";
import MonthDaysAndConfirmationPanel from "./MonthDaysAndConfirmationPanel";

const ReportsPage = ({ setSelectedPage }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [monthData, setMonthData] = useState(null);
  const [daysMonthData, setDaysMonthData] = useState([]);
  const [isCompletionDays, setIsCompletionDays] = useState(false);
  const [actualSection, setActualSection] = useState(null);

  const userJ = useMemo(() => JSON.parse(localStorage.getItem("userData")), []);

  const loadMonthData = useCallback(async () => {
    const month = currentMonth.toString().padStart(2, '0');
    const sendObj = {
      idno: userJ.IDNO,
      SessionKey: userJ.SessionKey,
      Date: `${currentYear}${month}`,
    };

    await getMonthData(
      "v2/volunteer/Maakav",
      sendObj,
      setLoading,
      setMonthData,
      setDaysMonthData,
      setIsCompletionDays
    );
  }, [currentMonth, currentYear, userJ]);

  useEffect(() => {
    loadMonthData();
  }, [loadMonthData]);

  const isActiveMonth = useMemo(() => changeDay(
    "checkActive",
    currentMonth,
    setCurrentMonth,
    currentYear,
    setCurrentYear
  ), [currentMonth, currentYear]);

  const isEditMode = useMemo(() => changeDay(
    "checkEditMode",
    currentMonth,
    setCurrentMonth,
    currentYear,
    setCurrentYear,
    monthData
  ), [currentMonth, currentYear, monthData]);

  const confirmMyMonth = useCallback(async () => {
    const month = currentMonth.toString().padStart(2, '0');
    const year = currentYear.toString();

    console.log("Confirming month with values:", {
      currentMonth,
      currentYear,
      formattedMonth: month,
      formattedYear: year,
      monthDataYear: monthData?.Year,
      monthDataMonth: monthData?.Month
    });

    const sendObj = {
      SessionKey: userJ.SessionKey,
      idno: userJ.IDNO,
      Year: year,
      Month: month,
      ReportID: monthData?.ReportID,
    };

    console.log("Sending confirmation request with:", sendObj);

    await confirmMonth(
      "v2/volunteer/maakavconfirm",
      sendObj,
      setLoading,
      loadMonthData
    );
  }, [userJ, monthData, loadMonthData, currentMonth, currentYear]);

  useEffect(() => {
    if (monthData) {
      console.log("Month Data Updated:", {
        Year: monthData.Year,
        Month: monthData.Month,
        ReportID: monthData.ReportID
      });
    }
  }, [monthData]);

  return (
    <div className={`ReportsPage ${isEditMode ? "editModePage" : ""} animate__animated animate__fadeIn`}>
      <img
        src={loader}
        alt="loader"
        className={loading ? "loader active animate__animated animate__fadeIn" : "loader"}
      />

      <header className="clear">
        <div style={{display: "grid", justifyContent: "center"}}>
          <h1>דיווחי נוכחות</h1>
          <br/>
          <div className="alert alert-warning" role="alert" style={{ textAlign: "center" }}>
            שימו לב לסדר התקין של השעות שמעדכנים שלא יהיו דיווחים שגויים או כפולים ⏰.
          </div>
          {isCompletionDays && (
            <div className="alert alert-info" role="alert" style={{ textAlign: "center" }}>
              שים לב, יש לוודא בטרם חתימה שהשלמת את כל הימים
            </div>
          )}
          {true && (
            <div className="confirmation">
              <button onClick={confirmMyMonth}>
                אישור חודש {showCurrentMonthName(currentMonth, currentYear, "monthNameOnly")}
              </button>
            </div>
          )}
        </div>
       
        <button className="backBtn" onClick={() => setSelectedPage("index")}>
          <img src={rightArrow} alt="rightArrow" />
        </button>
      </header>

      <div className="headerReportsPage">
        <button onClick={() => setActualSection("summary")}>
          <img src={sicum} alt="sicum" />
          <span>סיכום</span>
        </button>
        <button onClick={() => toast.error("אין API")}>
          <img src={sherut} alt="sherut" />
          <span>השירות שלי</span>
        </button>
      </div>

      {!actualSection ? (
        <Fragment>
          <nav className="monthsNav">
            <button
              onClick={() => changeDay("back", currentMonth, setCurrentMonth, currentYear, setCurrentYear)}
            >
              <img src={right} alt="right" style={{ marginLeft: "10px" }} />
              <span>הקודם</span>
            </button>

            <p>{showCurrentMonthName(currentMonth, currentYear)}</p>

            <button
              className={isActiveMonth ? "disabled" : ""}
              onClick={() => changeDay("forward", currentMonth, setCurrentMonth, currentYear, setCurrentYear)}
            >
              <span>הבא</span>
              <img src={left} alt="left" style={{ marginRight: "10px" }} />
            </button>
          </nav>

          <MonthDaysAndConfirmationPanel daysMonthData={daysMonthData} monthData={monthData} />

          {monthData && daysMonthData && (
            <div className="cardsReportCont">
              {daysMonthData.map((item) => (
                <CardDayReport
                  key={item.id}
                  item={item}
                  reloadData={loadMonthData}
                  monthData={monthData}
                  editMode={isEditMode}
                />
              ))}
            </div>
          )}
        </Fragment>
      ) : (
        actualSection === "summary" && <Summary setActualSection={setActualSection} />
      )}
    </div>
  );
};

const Summary = ({ setActualSection }) => {
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    const userJ = JSON.parse(localStorage.getItem("userData"));
    const sendObj = {
      sessionKey: userJ.SessionKey,
      requestID: "string",
      idno: userJ.IDNO,
      deviceID: "string"
    };

    getSummaryData("v2/Volunteer/summary", sendObj, setLoading, setSummaryData);
  }, []);

  return (
    <div className="Summary animate__animated animate__fadeIn">
      <img
        src={loader}
        alt="loader"
        className={loading ? "loader active animate__animated animate__fadeIn" : "loader"}
      />

      <h2>סיכום נוכחות</h2>

      {summaryData && (
        <div className="contSummary animate__animated animate__fadeIn">
          {summaryData.Years.map((item) => (
            <MyAccordiondata item={item} key={item.Year} />
          ))}

          <button className="backBtn" onClick={() => setActualSection(null)}>
            <span>אישור</span>
          </button>
        </div>
      )}
    </div>
  );
};

const MyAccordiondata = ({ item }) => {
  const changeNumber = (number) => {
    const numbersMap = {
      "1": "א'",
      "2": "ב'",
      "3": "ג'",
    };
    return numbersMap[number] || number;
  };

  return (
    <Accordion defaultActiveKey="0" className="openCLoseSumm">
      <Accordion.Item eventKey="01">
        <Accordion.Header>
          <span>שנה {changeNumber(item.Year)}</span>
        </Accordion.Header>
        <Accordion.Body>
          <ul>
            <li>
              <h3>ימי חופשה</h3>
              <p>
                עד כה ניצלת {item.UsedDaysOfVacation} ימים מתוך {item.GivenDaysOfVacation} ימי חופשה אותם צברת.
              </p>
              {parseFloat(item.VacationSummary) > 0 ? (
                <p>
                  *סה"כ עומדים לזכותך: <strong>{item.VacationSummary}</strong> ימי חופש
                </p>
              ) : (
                <p>
                  *סה"כ ימים בחוסר (לכל תקופת השירות): <strong style={{ color: "red" }}>{item.VacationSummary.replace("-", "")}</strong>
                </p>
              )}
            </li>
            {(parseFloat(item.ConfirmedSickDays) > 0 || parseFloat(item.UnconfirmedSickDays) > 0) && (
              <li>
                <h3>ימי מחלה</h3>
                <p>עד כה היו לך {item.ConfirmedSickDays} ימי מחלה מאושרים</p>
                {parseFloat(item.UnconfirmedSickDays) > 0 && (
                  <p style={{ marginBottom: "0" }}>
                    כמו כן חסרים לך אישורים על {item.UnconfirmedSickDays} ימי מחלה
                  </p>
                )}
                <p>
                  <strong>ללא אישורים, תאריכים אלו יחשבו כימי חופש</strong>
                </p>
              </li>
            )}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ReportsPage;
