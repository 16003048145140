import React from "react";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";

import place from "./../../../img/sherut-leumi/svg/search/resultsIcons/place.svg";
import phone from "./../../../img/sherut-leumi/svg/search/resultsIcons/phone.svg";
import mosdot from "./../../../img/sherut-leumi/svg/search/resultsIcons/mosdot.svg";
import agaa from "./../../../img/sherut-leumi/svg/search/resultsIcons/agaa.svg";

const ShowMoreData = ({ item }) => {
  const notes = item.Notes ? (item.Info ? `${item.Notes}, ` : item.Notes) : "";
  const fullInfo = `${notes}${item.Info || ""}`;

  return (
    <div style={{ padding: '2rem' }}>
      <h3>פרטים נוספים:</h3>
      <div style={{
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>

        <br/>
        {fullInfo && <article>{fullInfo}</article>}

        <ul style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'flex-start',
          padding: '10px',
          gap: '20px',
          width: '100%'
        }} className={isMobile ? "iconsLine" : "iconsLine twoColums"}>
          {item.Rak?.Mobile && (
            <li>
              <img src={phone} alt="" />
              <span>טלפון רכז/ת: </span> 
              <br/>
              <span>{item.Rak.Mobile}</span>
              {isMobile && (
                <a className="callBtn" href={`tel:${item.Rak.Mobile}`}>
                  חייגו &raquo;
                </a>
              )}
            </li>
          )}

          {item.ArriveTo && (
            <li>
              <img src={place} alt="" />
              <span>מקום מפגש: </span> <span>{item.ArriveTo}</span>
            </li>
          )}

          {item.Mosadot?.length > 0 && (
            <li>
              <img src={mosdot} alt="" />
              <span>מוסדות: </span> 
              <span>
                {item.Mosadot.map((mosad, index) => (
                  <React.Fragment key={mosad.ContractID}>
                    {index > 0 && ", "}
                    {mosad.Name}
                  </React.Fragment>
                ))}
              </span>
            </li>
          )}

          {item.ArriveNotes && (
            <li>
              <img src={agaa} alt="" />
              <span>דרכי הגעה: </span> <span>{item.ArriveNotes}</span>
            </li>
          )}

          {item.City?.Value && (
            <li>
              <img src={place} alt="" />
              <span>עיר: </span> <span>{item.City.Value}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ShowMoreData;
